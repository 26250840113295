import React from 'react';
import { Tabs } from 'antd';
import {
    DashboardOutlined,
    UserOutlined,
    DeleteOutlined,
    PhoneOutlined,
} from '@ant-design/icons';
import UserListTab from "./UserListTab";
import UserDashboardTab from "./UserDashboardTab";
import PhoneNumberTab from "./PhoneNumberTab";

const UserPage = () => {
  return (
    <Tabs
      items={[
        {
          label: (
            <span>
              <DashboardOutlined />
              대시보드
            </span>
          ),
          key: 0,
          children: <UserDashboardTab />,
        },
        {
          label: (
            <span>
              <UserOutlined />
              사용자
            </span>
          ),
          key: 1,
          children: <UserListTab />,
        },
        {
          label: (
            <span>
              <DeleteOutlined />
              탈퇴한 사용자
            </span>
          ),
          key: 2,
          children: <UserListTab deletedUser={true} />,
        },
        {
          label: (
            <span>
              <PhoneOutlined />
              전화번호
            </span>
          ),
          key: 3,
          children: <PhoneNumberTab />,
        },
      ]}
    />
  );
};

export default UserPage;
