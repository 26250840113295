import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCellCreatedAtList, getCellViewedAtList} from "../../../store/cells";
import TimeGraph from "../../organisms/TimeGraph";
import {Radio} from "antd";

const CellDashboardTab = () => {
  const dispatch = useDispatch();
  const {
    cellCreatedAtListLoading,
    cellCreatedAtList,
    cellViewedAtListLoading,
    cellViewedAtList,
  } = useSelector((state) => state.cells);
  const [category, setCategory] = useState('create')

  useEffect(() =>  {
    if (category === 'create' && cellCreatedAtList.length === 0) {
      dispatch(getCellCreatedAtList())
    } else if (category === 'view' && cellViewedAtList.length === 0) {
      dispatch(getCellViewedAtList())
    }
  }, [dispatch, category]);

  return (
    <>
      <TimeGraph
        categoryRadio={(
          <Radio.Group defaultValue='create' optionType="button" onChange={(e) => setCategory(e.target.value)}>
            <Radio.Button value='create'>생성</Radio.Button>
            <Radio.Button value='view'>조회</Radio.Button>
          </Radio.Group>
        )}
        loading={category === 'create' ? cellCreatedAtListLoading: cellViewedAtListLoading}
        timeKeyList={category === 'create' ? cellCreatedAtList : cellViewedAtList}
        yFieldName={category === 'create' ? '생성': '조회'}
      />
    </>
  );
};

export default CellDashboardTab;
