import { createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import {mergeDict} from "../utils";

const initialState = {
  collectionCreatedAtListLoading: true,
  collectionCreatedAtList: [],
  collectionViewedAtListLoading: true,
  collectionViewedAtList: [],
  collectionListObj: {
    isLoading: true,
    totalCount: 0,
    data: [],
  },
};

const slice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    setCollectionCreatedAtListLoading: (state, action) => ({
      ...state,
      collectionCreatedAtListLoading: action.payload
    }),
    setCollectionCreatedAtList: (state, action) => ({
      ...state,
      collectionCreatedAtListLoading: false,
      collectionCreatedAtList: action.payload,
    }),
    setCollectionViewedAtListLoading: (state, action) => ({
      ...state,
      collectionViewedAtListLoading: action.payload
    }),
    setCollectionViewedAtList: (state, action) => ({
      ...state,
      collectionViewedAtListLoading: false,
      collectionViewedAtList: action.payload,
    }),
    setCollectionListLoading: (state, action) => ({
      ...state,
      collectionListObj: {
        ...state.collectionListObj,
        isLoading: action.payload,
      }
    }),
    setCollectionList: (state, action) => ({
      ...state,
      collectionListObj: {
        ...state.collectionListObj,
        isLoading: false,
        totalCount: action.payload.totalCount,
        data: action.payload.collections,
      }
    }),
    selectCollection: (state, action) => ({ ...state, selectedCollection: action.payload }),
    updateCollection: (state, action) => {
      const newOne = action.payload.collection
      const oldOne = state.collectionList.data.find(collection => collection.id === newOne?.id)
      if (oldOne) {
        mergeDict(oldOne, newOne)
      }
    },
  },
});

export default slice.reducer;

// Actions

export const {
  setCollectionCreatedAtListLoading,
  setCollectionCreatedAtList,
  setCollectionViewedAtListLoading,
  setCollectionViewedAtList,
  setCollectionListLoading,
  setCollectionList,
  selectCollection,
  updateCollection,
} = slice.actions;

export const getCollectionCreatedAtList = () => async (dispatch) => {
  dispatch(setCollectionCreatedAtListLoading(true));
  axios
    .get('/v1/admin/collections/createdAt')
    .then(res => {
      dispatch(
        setCollectionCreatedAtList(
          res.data.map((time, key) => ({ time, key }))
        )
      );
    })
    .catch(e =>
      dispatch(setCollectionCreatedAtListLoading(false))
    )
};

export const getCollectionViewedAtList = () => async (dispatch) => {
  dispatch(setCollectionViewedAtListLoading(true));
  axios
    .get('/v1/admin/collections/viewedAt')
    .then(res => {
      dispatch(
        setCollectionViewedAtList(
          res.data.map((item, index) => ({ time: item.viewedAt, key: index }))
        )
      );
    })
    .catch(e =>
      dispatch(setCollectionViewedAtListLoading(false))
    )
};

export const getCollectionList = (query) => async (dispatch) => {
  dispatch(setCollectionListLoading(true));

  const params  = query ?? { skip: 0, limit: 10 }

  axios
    .get('/v1/admin/collections', { params })
    .then(res => {
      dispatch(setCollectionList(res.data))
    })
    .catch(e =>
      dispatch(setCollectionListLoading(false))
    )
};
