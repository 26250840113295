import {Button, Input, Space, Table} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {useState} from "react";

const SearchableTable = ({
                           dataSource,
                           columns,
                           loading,
                           onRowClick,
                           loadData,
                           totalCount,
                         }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex, column) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: loadData ? undefined : ((value, record) => (
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : ''
    )),
    render: column.render ?? ((text) => (
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
    )),
  });

  const searchableColumns = columns.map(column => {
    return column.isSearchable
      ? {
        ...column,
        ...getColumnSearchProps(column.dataIndex, column)
      }
      : column
  })

  return (
    <Table
      dataSource={dataSource}
      columns={searchableColumns}
      bordered
      pagination={{ position: ['bottomCenter'], total: totalCount }}
      tableLayout="auto"
      loading={loading}
      onChange={(pagination, filters, sorter, extra) => {
        if (loadData) {
          const { current, pageSize} = pagination;

          const query = {
            skip: (current - 1) * pageSize,
            limit: pageSize,
          }

          for (const key in filters) {
            if (filters[key]) {
              query[key] = filters[key];
            }
          }

          if (sorter?.order) {
            query.sortBy = sorter.field;
            query.order = sorter.order === 'ascend' ? 'ASC' : 'DESC';
          }

          loadData(query);
        }
      }}
      onRow={(record, rowIndex) => {
        return {
          onClick: () => onRowClick && onRowClick(record)
        };
      }}
    />
  )
}

export default SearchableTable;
