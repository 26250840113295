import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {getCollectionList, selectCollection} from '../../../store/collections';
import moment from "moment";
import SearchableTable from "../../organisms/SearchableTable";
import CollectionDetailDrawer from "./CollectionDetailDrawer";
import { publishStatus } from "../../../utils/constants";

const CollectionListTab = () => {
  const dispatch = useDispatch();

  const { collectionListObj } = useSelector((state) => state.collections);

  const _collectionListLoading = collectionListObj.isLoading
  const _totalCount = collectionListObj.totalCount
  const _collectionList = collectionListObj.data

  useEffect(() =>  {
    dispatch(getCollectionList())
  }, [dispatch]);

  const columns = [
    {
      title: '상태',
      dataIndex: 'status',
      render: (status) => publishStatus[status] ?? status,
    },
    {
      title: '제목',
      dataIndex: 'topic',
      isSearchable: true,
    },
    {
      title: '생성 시간',
      dataIndex: 'createdAt',
      render: (createdAt) => moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '제작자',
      dataIndex: ['creator', 'name'],
      isSearchable: true,
    },
    {
      title: '포함 페이지 수',
      dataIndex: 'cellCount',
    },
    {
      title: '조회수',
      dataIndex: 'viewCount',
      sorter: (a, b) => a.viewCount - b.viewCount,
      sortDirections: ['descend', 'ascend'],
    },
  ]

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <span style={{ fontSize: 30, margin: 5 }}>
          미니북
        </span>
        <span style={{ fontSize: 15, margin: 5 }}>
          전체 {_totalCount}개
        </span>
      </div>
      <SearchableTable
        dataSource={_collectionList}
        columns={columns}
        loading={_collectionListLoading}
        totalCount={_totalCount}
        onRowClick={(record) => dispatch(selectCollection(record))}
        loadData={(query) => {
          dispatch(getCollectionList(query))
        }}
      />
      <CollectionDetailDrawer />
    </>
  );
};

export default CollectionListTab;
