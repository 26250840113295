import React, {useEffect} from 'react';
import './App.css';
import MainPage from './components/containers/MainPage';
import LoginPage from './components/containers/LoginPage';
import {getAccessToken, getSavedUserInfo, setAuthHeader} from "./utils/authorization";
import {useDispatch, useSelector} from "react-redux";
import {clearUserInfo, saveUserInfo} from "./store/auth";
import LoadingPage from "./components/containers/LoadingPage";
import {USER_CLASS_ADMIN} from "./utils/constants";
import NotAuthorizedPage from "./components/pages/NotAuthorizedPage";

const App = () => {
  const dispatch = useDispatch()
  const { isLoading, userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    const user = getSavedUserInfo()
    const accessToken = getAccessToken()
    if (user && accessToken) {
      dispatch(saveUserInfo(user))
      setAuthHeader(accessToken);
    } else {
      dispatch(clearUserInfo())
    }
  }, []);

  return (
    isLoading ? <LoadingPage />
      : userInfo?.userClasses?.includes(USER_CLASS_ADMIN) ? <MainPage />
        : userInfo ? <NotAuthorizedPage />
          : <LoginPage />
  )
};

export default App;
