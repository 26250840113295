import { createSlice } from '@reduxjs/toolkit';
import axios from "axios";

const initialState = {
  purchaseListLoading: true,
  purchaseList: [],
  earnerListLoading: true,
  earnerList: [],
  settlementListLoading: true,
  settlementList: [],
};

const slice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    setPurchaseListLoading: (state, action) => ({
      ...state,
      purchaseListLoading: action.payload
    }),
    setPurchaseList: (state, action) => ({
      ...state,
      purchaseListLoading: false,
      purchaseList: action.payload,
    }),
    setEarnerListLoading: (state, action) => ({
      ...state,
      earnerListLoading: action.payload
    }),
    setEarnerList: (state, action) => ({
      ...state,
      earnerListLoading: false,
      earnerList: action.payload,
    }),
    setSettlementListLoading: (state, action) => ({
      ...state,
      settlementListLoading: action.payload
    }),
    setSettlementList: (state, action) => ({
      ...state,
      settlementListLoading: false,
      settlementList: action.payload,
    }),
  },
});

export default slice.reducer;

// Actions
export const {
  setPurchaseListLoading,
  setPurchaseList,
  setEarnerListLoading,
  setEarnerList,
  setSettlementListLoading,
  setSettlementList,
} = slice.actions;

export const getPurchaseList = () => async (dispatch) => {
  dispatch(setPurchaseListLoading(true));
  axios
    .get('/v1/admin/purchases')
    .then(res => {
      dispatch(setPurchaseList(res.data))
    })
    .catch(e =>
      dispatch(setPurchaseListLoading(false))
    )
};

export const getEarnerList = () => async (dispatch) => {
  dispatch(setEarnerListLoading(true));
  axios
    .get('/v1/admin/earners')
    .then(res => {
      dispatch(setEarnerList(res.data))
    })
    .catch(e =>
      dispatch(setEarnerListLoading(false))
    )
};

export const getSettlementList = (year, half) => async (dispatch) => {
  dispatch(setSettlementListLoading(true));
  axios
    .get(`/v1/admin/settlements/${year}/${half}`)
    .then(res => {
      dispatch(setSettlementList(res.data))
    })
    .catch(e =>
      dispatch(setSettlementListLoading(false))
    )
};
