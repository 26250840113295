import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getSubscriptionList} from "../../../store/subscriptions";
import TimeGraph from "../../organisms/TimeGraph";
import {Radio} from "antd";

const CATEGORY_COUNT = 'count'
const CATEGORY_AMOUNT = 'amount'

const SubscriptionDashboardTab = () => {
  const dispatch = useDispatch();
  const {
    subscriptionListLoading,
    subscriptionList
  } = useSelector((state) => state.subscriptions);
  const [category, setCategory] = useState(CATEGORY_COUNT)
  const [subscriptionCountList, setSubscriptionCountList] = useState([])
  const [subscriptionAmountList, setSubscriptionAmountList] = useState([])

  useEffect(() =>  {
    dispatch(getSubscriptionList())
  }, [dispatch, category]);

  useEffect(() =>  {
    if (subscriptionList) {
      setSubscriptionCountList(
        subscriptionList.map(subscription => ({
          time: subscription.createdAt,
          key: subscription.id,
        }))
      )
      setSubscriptionAmountList(
        subscriptionList.map(subscription => ({
          time: subscription.createdAt,
          key: subscription.id,
          amount: subscription.amount ?? 0,
        }))
      )
    }
  }, [subscriptionList]);

  return (
    <>
      <TimeGraph
        categoryRadio={(
          <Radio.Group defaultValue={CATEGORY_COUNT} optionType="button" onChange={(e) => setCategory(e.target.value)}>
            <Radio.Button value={CATEGORY_COUNT}>건수</Radio.Button>
            <Radio.Button value={CATEGORY_AMOUNT}>금액</Radio.Button>
          </Radio.Group>
        )}
        loading={subscriptionListLoading}
        timeKeyList={category === CATEGORY_COUNT ? subscriptionCountList : subscriptionAmountList}
        yFieldName={category === CATEGORY_COUNT ? '건': '금액'}
      />
    </>
  );
};

export default SubscriptionDashboardTab;
