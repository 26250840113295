import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {getSettlementList} from '../../../store/purchases';
import moment from "moment";
import SearchableTable from "../../organisms/SearchableTable";
import {Button, Form, Input, Modal, Select} from "antd";
import axios from "axios";
import {amountText, amountTextByCurrency} from "../../../utils";
import {currencies, currenciesByCountryCode} from "../../../utils/constants";
const { Option } = Select;

const SettlementListTab = () => {
  const startYear = 2020;
  const thisYear = moment().year();
  const years = Array.from(
    {length: thisYear - startYear + 1},
    (_, i) => String(thisYear - i)
  )
  const thisHalf = moment().month() < 6 ? 1 : 2

  const dispatch = useDispatch();
  const { settlementList, settlementListLoading } = useSelector((state) => state.purchases);
  const [ selectedYear, setSelectedYear ] = useState(String(thisYear));
  const [ selectedHalf, setSelectedHalf ] = useState(String(thisHalf));

  const [settlementTarget, setSettlementTarget] = useState(undefined);
  const [inputLoading, setInputLoading] = useState(false);
  const [inputCountryCode, setInputCountryCode] = useState('');
  const [inputBankName, setInputBankName] = useState('');
  const [inputBankAccountNumber, setInputBankAccountNumber] = useState('');
  const [inputBankAccountHolder, setInputBankAccountHolder] = useState('');
  const [inputTotalAmount, setInputTotalAmount] = useState('');
  const [inputCurrency, setInputCurrency] = useState('');
  const [inputAmount, setInputAmount] = useState(0);
  const [inputTax, setInputTax] = useState(0);


  const columns = [
    {
      title: '미니북 제목',
      dataIndex: ['collection','topic'],
      isSearchable: true,
    },
    {
      title: '미니북 언어',
      dataIndex: 'language',
    },
    {
      title: '수익자',
      dataIndex: ['earner','email'],
      isSearchable: true,
    },
    {
      title: '총 수익액',
      dataIndex: 'totalAmount',
      render: (totalAmount) => amountTextByCurrency(totalAmount),
    },
    {
      title: '정산 금액',
      dataIndex: ['settlement', 'amount'],
      render: (amount, record) => amount ? amountText(amount, record?.settlement?.currency) : '-',
    },
    {
      title: '정산 세금',
      dataIndex: ['settlement', 'tax'],
      render: (tax, record) => tax ? amountText(tax, record?.settlement?.currency) : '-',
    },
    {
      title: '정산 정보',
      key: 'action',
      render: (record) => (
        <Button type="primary" onClick={(e) => showInputModal(e, record)} >
          { record.settlement ? '수정' : '입력'}
        </Button>
      ),
    },
  ]

  useEffect(() =>  {
    dispatch(getSettlementList(thisYear, thisHalf))
  }, [dispatch]);

  const showInputModal = async (e, record) =>  {
    const { earner, settlement, totalAmount } = record
    e.stopPropagation()

    if (settlement) {
      setInputCountryCode(settlement.countryCode);
      setInputBankName(settlement.bankName);
      setInputBankAccountNumber(settlement.bankAccountNumber);
      setInputBankAccountHolder(settlement.bankAccountHolder);
      setInputTotalAmount(amountTextByCurrency(totalAmount));
      setInputCurrency(settlement.currency);
      setInputAmount(settlement.amount);
      setInputTax(settlement.tax);
      setSettlementTarget(record);
    } else {
      const { data: { accountInfo } } = await axios.get(`/v1/admin/accountInfo/${earner.id}`)
      setInputCountryCode(accountInfo.countryCode);
      setInputBankName(accountInfo.bankName);
      setInputBankAccountNumber(accountInfo.bankAccountNumber);
      setInputBankAccountHolder(accountInfo.bankAccountHolder);
      setInputTotalAmount(amountTextByCurrency(totalAmount));
      setInputCurrency(currenciesByCountryCode[accountInfo.countryCode] ?? 'USD');
      setInputAmount(0);
      setInputTax(0);
      setSettlementTarget(record);
    }
  };

  const hideInputModal = () => {
    setSettlementTarget(undefined);
  };


  const handleOk = () => {
    setInputLoading(true);
    axios.post('/v1/admin/settlements', {
      userId: settlementTarget.earner.id,
      collectionId: settlementTarget.collection.id,
      language: settlementTarget.language,
      countryCode: inputCountryCode,
      bankName: inputBankName,
      bankAccountNumber: inputBankAccountNumber,
      bankAccountHolder: inputBankAccountHolder,
      currency: inputCurrency,
      amount: inputAmount,
      tax: inputTax,
      year: selectedYear,
      half: selectedHalf,
    })
      .then(() => {
        hideInputModal();
        setInputLoading(false);
      })
      .catch((e) => {
        console.log(e)
        setInputLoading(false);
      });
  };

  const onChangePeriod = ({year, half}) => {
    setSelectedYear(year)
    setSelectedHalf(half)
    dispatch(getSettlementList(year, half));
  }

  return (
    <>
      <div style={{marginBottom: 10}}>
        <span style={{fontSize: 30, margin: 5}}>
          정산 내역
        </span>
        <Select value={selectedYear} onChange={(value) => onChangePeriod({year: value, half: selectedHalf})}>
          {
            years.map(year => (
              <Option value={year}>{`${year}년`}</Option>
            ))
          }
        </Select>
        <Select value={selectedHalf} onChange={(value) => onChangePeriod({year: selectedYear, half: value})}>
          <Option value="1">상반기</Option>
          <Option value="2">하반기</Option>
        </Select>
        <span style={{fontSize: 15, margin: 5}}>
          전체 {settlementList.length}개
        </span>
      </div>
      <SearchableTable
        dataSource={settlementList}
        columns={columns}
        loading={settlementListLoading}
      />
      <Modal
        title="정산 내역 입력/수정"
        open={!!settlementTarget}
        onOk={handleOk}
        confirmLoading={inputLoading}
        onCancel={hideInputModal}
      >
        <Form.Item label="국가 코드">
          <Input value={inputCountryCode} onChange={(e) => setInputCountryCode(e.target.value)} />
        </Form.Item>
        <Form.Item label="은행 이름">
          <Input value={inputBankName} onChange={(e) => setInputBankName(e.target.value)} />
        </Form.Item>
        <Form.Item label="계좌 번호">
          <Input value={inputBankAccountNumber} onChange={(e) => setInputBankAccountNumber(e.target.value)} />
        </Form.Item>
        <Form.Item label="예금주">
          <Input value={inputBankAccountHolder} onChange={(e) => setInputBankAccountHolder(e.target.value)} />
        </Form.Item>
        <Form.Item label="총 수익액" >
          {inputTotalAmount}
        </Form.Item>
        <Form.Item label="입금화폐">
          <Select options={currencies.map((v) => ({ value: v }))} value={inputCurrency} onChange={(value) => setInputCurrency(value)}/>
        </Form.Item>
        <Form.Item label="입금액">
          <Input value={inputAmount} onChange={(e) => setInputAmount(e.target.value)} />
        </Form.Item>
        <Form.Item label="세금">
          <Input value={inputTax} onChange={(e) => setInputTax(e.target.value)} />
        </Form.Item>
      </Modal>
    </>
  );
};

export default SettlementListTab;
