import React from 'react';
import { Button, Result } from 'antd';
import {isProductionServer} from "../../utils/variables";
import {logout} from "../../utils/authorization";

const NotFoundPage = (props) => {
  return (
    <Result
      status="403"
      title="관리자 페이지"
      subTitle="관리자만 접근할 수 있습니다."
      extra={
        <>
          <Button href={isProductionServer ? "https://youbook.biz" : "https://dev.youbook.biz"} >유북 페이지</Button>
          <Button type="primary" onClick={logout}>다시 로그인 하기</Button>
        </>
      }
    />
  );
};

export default NotFoundPage;
