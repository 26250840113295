import React, {useEffect, useState} from 'react';
import {
  Button,
  Drawer,
  Form,
  Image,
  Input,
  message,
  Space,
  Switch,
  Checkbox,
  Modal,
} from "antd";
import {allUserClasses} from "../../../utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {selectUser, updateUser} from "../../../store/users";
import axios from "axios";
import moment from "moment";

const UserDetailDrawer = () => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const { selectedUser } = useSelector((state) => state.users);

  const [mobile, setMobile] = useState(selectedUser?.mobile);

  const [smsTarget, setSmsTarget] = useState(null);
  const [smsText, setSmsText] = useState(null);
  const [isSendingSms, setIsSendingSms] = useState(false);

  useEffect(() => {
    if (!!selectedUser) {
      form.setFieldsValue(selectedUser)
      setMobile(selectedUser.mobile)
    } else {
      form.resetFields()
    }
  }, [form, selectedUser]);

  const closeDrawer = () => {
    dispatch(selectUser(null))
  }

  const key = 'update_server';
  const onFinish = (values) => {
    values.mobile = mobile

    messageApi.open({
      key,
      type: 'loading',
      content: '서버에 업로드 중...',
    });

    axios.put(`/v1/admin/users/${values.id}`, values)
      .then(res => {
        closeDrawer()
        messageApi.open({
          key,
          type: 'success',
          content: '수정되었습니다.',
        });
        dispatch(updateUser(res.data))
      })
      .catch(err => {
        messageApi.destroy(key)
      })
  };

  const showSmsModal = (target) => {
    if (target.group && selectedUser.contactsCount === 0) {
      messageApi.error('보낼 연락처가 없습니다.');
      return
    }
    if (!target.group && !target.number) {
      messageApi.error('휴대폰 번호가 없습니다.');
      return
    }

    setSmsText('')
    setSmsTarget(target)
  }

  const hideSmsModal = () => {
    setSmsTarget(null)
  }

  const sendSms = () => {
    if (!smsText) {
        messageApi.error('메시지를 입력하세요.');
        return;
    }

    setIsSendingSms(true)

    axios.post('/v1/admin/sms', {
      target: smsTarget,
      text: smsText,
    })
        .then((res) => {
          console.log(res)
          hideSmsModal()
          setIsSendingSms(false)
          messageApi.success('문자 메시지를 성공적으로 보냈습니다.');
        })
        .catch((e) => {
          console.log(e)
          setIsSendingSms(false)
          messageApi.error(`문자 메시지를 보내는데 실패했습니다. (${e.response?.data?.message})`);
        })
  }

  return (
      <>
        {contextHolder}
        <Drawer
            title="사용자 정보 수정"
            width={800}
            onClose={closeDrawer}
            open={!!selectedUser}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
              <Space>
                <Button onClick={closeDrawer}>취소</Button>
                <Button onClick={() => form.submit()} type="primary">수정</Button>
              </Space>
            }
        >
          <Form
            layout="horizontal"
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 5 }}
          >
            <Form.Item label="가입일">
              <Input disabled value={moment(selectedUser?.createdAt).format('YYYY년 MM월 DD일')}/>
            </Form.Item>
            <Form.Item name="id" label="uuid">
              <Input disabled />
            </Form.Item>
            <Form.Item name="email" label="이메일">
              <Input disabled />
            </Form.Item>
            <Form.Item name="collectionCount" label="만든 컬렉션 수">
              <Input disabled />
            </Form.Item>
            <Form.Item name="language" label="언어">
              <Input />
            </Form.Item>
            <Form.Item name="userClasses" label="클래스">
              <Checkbox.Group options={allUserClasses} />
            </Form.Item>
            <Form.Item valuePropName="src" name="photo" label="사진">
              <Image style={{ width:"118px", height:"118px", objectFit:"contain" }} />
            </Form.Item>
            <Form.Item name="mobile" label="휴대폰 번호">
              <div style={{display: "flex"}}>
                <Input style={{flex: 1}} value={mobile} onChange={(e) => setMobile(e.target.value)} />
                <Button style={{marginLeft: "8px"}} type="primary" onClick={() => showSmsModal({number: selectedUser?.mobile})}>사용자에게 SMS 전송</Button>
              </div>
            </Form.Item>
            <Form.Item name="contactsCount" label="업로드 연락처">
              <div style={{display: "flex"}}>
                <Input style={{flex: 1}} value={`${selectedUser?.contactsCount} 명`} disabled bordered={false} style={{ color: 'black' }}/>
                <Button style={{marginLeft: "8px"}} type="primary" onClick={() => showSmsModal({group: selectedUser?.id})}>연락처 모든 번호로 SMS 전송</Button>
              </div>
            </Form.Item>
            <Form.Item name="name" label="이름">
              <Input/>
            </Form.Item>
            <Form.Item name="introduction" label="소개">
              <Input/>
            </Form.Item>
            <Form.Item name="profile" label="관심분야">
              <Input.TextArea rows={4} placeholder="관심분야를 입력하세요" />
            </Form.Item>
          </Form>
        </Drawer>
        <Modal
            title={`${selectedUser?.email}${(smsTarget?.group ? ` 의 연락처(${selectedUser?.contactsCount}명)` : `(${selectedUser?.mobile})`)} 에게 SMS 전송`}
            open={!!smsTarget}
            onOk={() => sendSms()}
            confirmLoading={isSendingSms}
            onCancel={hideSmsModal}
            maskClosable={false}>
          <Input.TextArea rows={10} value={smsText} onChange={(e)=>{setSmsText(e.target.value)}}/>
        </Modal>
      </>
  );
};

export default UserDetailDrawer;
