export const MENU_USERS = 'users'
export const MENU_CELLS = 'cells'
export const MENU_SUBSCRIPTIONS = 'subscriptions'
export const MENU_PURCHASES = 'purchases'
export const MENU_COLLECTIONS = 'collections'
export const MENU_FAQS = 'faqs'
export const DEFAULT_MENU = MENU_USERS

export const USER_CLASS_ADMIN = '관리자'
export const USER_CLASS_NOTHING = '(없음)'

export const currencies = [
  'USD',
  'CNY',
  'KRW',
  'JPY',
  'IDR',
  'THB',
  'VND',
  'RUB',
  'EUR',
];

export const currenciesByLanguageCode = {
  en: 'USD', // United States Dollar
  zh: 'CNY', // Chinese Yuan
  ko: 'KRW', // South Korean Won
  ja: 'JPY', // Japanese Yen
  id: 'IDR', // Indonesian Rupiah
  th: 'THB', // Thai Baht
  vi: 'VND', // Vietnamese Dong
  ru: 'RUB', // Russian Ruble
  de: 'EUR', // Euro (Germany)
  it: 'EUR', // Euro (Italy)
  fr: 'EUR', // Euro (France)
  es: 'EUR', // Euro (Spain)
};

export const currenciesByCountryCode = {
  us: "USD",
  cn: "CNY",
  kr: "KRW",
  jp: "JPY",
  id: "IDR",
  th: "THB",
  vn: "VND",
  ru: "RUB",
  de: "EUR",
  it: "EUR",
  fr: "EUR",
  es: "EUR"
}

export const exchangeRateByCurrency = {
  USD: 1,
  CNY: 7, // 환율 USD -> KRW(한국 원)
  KRW: 1300, // 환율 USD -> CNY(중국 위안)
  JPY: 110, // 환율 USD -> JPY(일본 엔)
  IDR: 14000, // 환율 USD -> IDR(인도네시아 루피아)
  THB: 31, // 환율 USD -> THB(태국 바트)
  VND: 23000, // 환율 USD -> VND(베트남 동)
  RUB: 71, // 환율 USD -> RUB(러시아 루블)
  EUR: 0.86, // 환율 USD -> EUR(유럽 유로 - 독일, 이탈리아, 프랑스, 스페인)
}

export const allUserClasses = [
  '관리자',
  '유북 강사',
  '내부자들',
  USER_CLASS_NOTHING,
];

export const publishStatus = {
  "published": "📚 출판 됨",
  "draft": "✏️ 작성 중",
  "pending": "🕑 출판 대기 중",
}

export const referenceTypes = [
  '책',
  '블로그',
  '직접 작성',
];

export const majorLanguageCodes = [
  'zh',
  'en',
  'fr',
  'de',
  'id',
  'it',
  'ko',
  'ja',
  'ru',
  'es',
  'th',
  'vi',
  'ar'
];

export const languageCodes = {
  'aa': '아파르어',
  'ab': '압하스어',
  'ae': '아베스타어',
  'af': '아프리칸스어',
  'ak': '아칸어',
  'am': '암하라어',
  'an': '아라곤어',
  'ar': '아랍어',
  'as': '아삼어',
  'av': '아바르어',
  'ay': '아이마라어',
  'az': '아제르바이잔어',
  'ba': '바시키르어',
  'be': '벨라루스어',
  'bg': '불가리아어',
  'bh': '비하리어',
  'bi': '비스라마어',
  'bm': '밤바라어',
  'bn': '벵골어',
  'bo': '티베트어',
  'br': '브르타뉴어',
  'bs': '보스니아어',
  'ca': '카탈루냐어',
  'ce': '체첸어',
  'ch': '차모로어',
  'co': '코르시카어',
  'cr': '크리어',
  'cs': '체코어',
  'cu': '교회 슬라브어',
  'cv': '추바시어',
  'cy': '웨일스어',
  'da': '덴마크어',
  'de': '독일어',
  'dv': '디베히어',
  'dz': '종카어',
  'ee': '에웨어',
  'el': '그리스어',
  'en': '영어',
  'eo': '에스페란토',
  'es': '스페인어',
  'et': '에스토니아어',
  'eu': '바스크어',
  'fa': '페르시아어',
  'ff': '풀라어',
  'fi': '핀란드어',
  'fj': '피지어',
  'fo': '페로어',
  'fr': '프랑스어',
  'fy': '서프리슬란트어',
  'ga': '아일랜드어',
  'gd': '스코틀랜드 게일어',
  'gl': '갈리시아어',
  'gn': '과라니어',
  'gu': '구자라트어',
  'gv': '맨어',
  'ha': '하우사어',
  'he': '히브리어',
  'hi': '힌디어',
  'ho': '히리모투어',
  'hr': '크로아티아어',
  'ht': '아이티 크리올어',
  'hu': '헝가리어',
  'hy': '아르메니아어',
  'hz': '헤레로어',
  'ia': '인터링구아',
  'id': '인도네시아어',
  'ie': '인터링구에',
  'ig': '이그보어',
  'ii': '쓰촨 이어',
  'ik': '이누피아크어',
  'io': '이도어',
  'is': '아이슬란드어',
  'it': '이탈리아어',
  'iu': '이누크티투트어',
  'ja': '일본어',
  'jv': '자바어',
  'ka': '조지아어',
  'kg': '콩고어',
  'ki': '키쿠유어',
  'kj': '콴야마어',
  'kk': '카자흐어',
  'kl': '그린란드어',
  'km': '크메르어',
  'kn': '칸나다어',
  'ko': '한국어',
  'kr': '카누리어',
  'ks': '카슈미르어',
  'ku': '쿠르드어',
  'kv': '코미어',
  'kw': '콘월어',
  'ky': '키르기스어',
  'la': '라틴어',
  'lb': '룩셈부르크어',
  'lg': '간다어',
  'li': '림뷔르흐어',
  'ln': '링갈라어',
  'lo': '라오어',
  'lt': '리투아니아어',
  'lu': '루바카탕가어',
  'lv': '라트비아어',
  'mg': '말라가시어',
  'mh': '마셜어',
  'mi': '마오리어',
  'mk': '마케도니아어',
  'ml': '말라얄람어',
  'mn': '몽골어',
  'mr': '마라티어',
  'ms': '말레이어',
  'mt': '몰타어',
  'my': '버마어',
  'na': '나우루어',
  'nb': '노르웨이 북부어',
  'nd': '북은데벨레어',
  'ne': '네팔어',
  'ng': '은동가어',
  'nl': '네덜란드어',
  'nn': '노르웨이 니노르스크어',
  'no': '노르웨이어',
  'nr': '남은데벨레어',
  'nv': '나바호어',
  'ny': '체와어',
  'oc': '오크어',
  'oj': '오지브와어',
  'om': '오로모어',
  'or': '오리야어',
  'os': '오세트어',
  'pa': '펀자브어',
  'pi': '팔리어',
  'pl': '폴란드어',
  'ps': '파슈토어',
  'pt': '포르투갈어',
  'qu': '케추아어',
  'rm': '로만슈어',
  'rn': '룬디어',
  'ro': '루마니아어',
  'ru': '러시아어',
  'rw': '키냐르완다어',
  'sa': '산스크리트어',
  'sc': '사르데냐어',
  'sd': '신디어',
  'se': '북부 사미어',
  'sg': '상고어',
  'si': '싱할라어',
  'sk': '슬로바키아어',
  'sl': '슬로베니아어',
  'sm': '사모아어',
  'sn': '쇼나어',
  'so': '소말리아어',
  'sq': '알바니아어',
  'sr': '세르비아어',
  'ss': '스와티어',
  'st': '남소토어',
  'su': '순다어',
  'sv': '스웨덴어',
  'sw': '스와힐리어',
  'ta': '타밀어',
  'te': '텔루구어',
  'tg': '타지크어',
  'th': '태국어',
  'ti': '티그리냐어',
  'tk': '투르크멘어',
  'tl': '타갈로그어',
  'tn': '츠와나어',
  'to': '통가어',
  'tr': '터키어',
  'ts': '총가어',
  'tt': '타타르어',
  'tw': '트위어',
  'ty': '타히티어',
  'ug': '위구르어',
  'uk': '우크라이나어',
  'ur': '우르두어',
  'uz': '우즈베크어',
  've': '벤다어',
  'vi': '베트남어',
  'vo': '볼라퓌크어',
  'wa': '왈롱어',
  'wo': '월로프어',
  'xh': '코사어',
  'yi': '이디시어',
  'yo': '요루바어',
  'za': '좡어',
  'zh': '중국어',
  'zu': '줄루어',
}
