import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getPurchaseList} from "../../../store/purchases";
import TimeGraph from "../../organisms/TimeGraph";
import {Radio} from "antd";

const CATEGORY_COUNT = 'count'
const CATEGORY_AMOUNT = 'amount'

const PurchaseDashboardTab = () => {
  const dispatch = useDispatch();
  const {
    purchaseListLoading,
    purchaseList
  } = useSelector((state) => state.purchases);
  const [category, setCategory] = useState(CATEGORY_COUNT)
  const [purchaseCountList, setPurchaseCountList] = useState([])
  const [purchaseAmountList, setPurchaseAmountList] = useState([])

  useEffect(() =>  {
    dispatch(getPurchaseList())
  }, [dispatch, category]);

  useEffect(() =>  {
    if (purchaseList) {
      setPurchaseCountList(
        purchaseList.map(purchase => ({
          time: purchase.createdAt,
          key: purchase.id,
        }))
      )
      setPurchaseAmountList(
        purchaseList.map(purchase => {
          let amount = purchase.amount ?? 0;
          if (purchase.currency === 'USD') {
            amount *= 1000;
          }
          return {
            time: purchase.createdAt,
            key: purchase.id,
            amount,
          }
        })
      )
    }
  }, [purchaseList]);

  return (
    <>
      <TimeGraph
        categoryRadio={(
          <Radio.Group defaultValue={CATEGORY_COUNT} optionType="button" onChange={(e) => setCategory(e.target.value)}>
            <Radio.Button value={CATEGORY_COUNT}>건수</Radio.Button>
            <Radio.Button value={CATEGORY_AMOUNT}>금액</Radio.Button>
          </Radio.Group>
        )}
        loading={purchaseListLoading}
        timeKeyList={category === CATEGORY_COUNT ? purchaseCountList : purchaseAmountList}
        yFieldName={category === CATEGORY_COUNT ? '건': '금액'}
      />
    </>
  );
};

export default PurchaseDashboardTab;
