import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Button, Modal} from 'antd';
import {deleteFaqFromList, selectFaq, setFaqList, setFaqListLoading} from "../../../store/faqs";
import axios from "axios";
import FaqDetailDrawer from "./FaqDetailDrawer";
import SearchableTable from "../../organisms/SearchableTable";

const FaqListTab = () => {
  const dispatch = useDispatch();

  const { faqListLoading, faqList } = useSelector((state) => state.faqs);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const showDeleteModal = (e, faq) => {
    e.stopPropagation()
    setDeleteTarget(faq);
  };

  const hideDeleteModal = () => {
    setDeleteTarget(null);
  };

  useEffect(() => {
    dispatch(setFaqListLoading(true));
    axios
      .get('/v1/admin/faqs')
      .then((res) => {
        dispatch(setFaqList(res.data))
      })
      .catch(e =>
        dispatch(setFaqListLoading(false))
      )
  }, [dispatch]);

  const deleteFaq = () => {
    if (deleteTarget) {
      setIsDeleting(true)
      axios
        .delete(`/v1/admin/faqs/${deleteTarget._id}`)
        .then((res) => {
          dispatch(deleteFaqFromList(res.data))
          setDeleteTarget(null)
          setIsDeleting(false)
        })
        .catch(e => {
          setIsDeleting(false);
        })
    }
  }

  const columns = [
    {
      title: '순서',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: '제목',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: '',
      key: 'action',
      render: (record) => (
        <Button type="link" onClick={(e) => showDeleteModal(e, record)} >삭제</Button>
      ),
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <span style={{ fontSize: 30, margin: 5 }}>FAQ</span>
        <span style={{ fontSize: 15, margin: 5 }}>전체 {faqList.length}개</span>
      </div>
      <SearchableTable
        dataSource={faqList}
        loading={faqListLoading}
        columns={columns}
        onRowClick={(record) => dispatch(selectFaq(record))}
      />
      <div style={{ marginTop: faqList.length ? -50 : 10 }}>
        <Button
          type="primary"
          style={{ float: 'right', display: 'inline-block' }}
          onClick={() => dispatch(selectFaq({})) }>
          생성
        </Button>
      </div>
      <FaqDetailDrawer />
      <Modal
        title="FAQ 삭제"
        open={!!deleteTarget}
        onOk={deleteFaq}
        confirmLoading={isDeleting}
        onCancel={hideDeleteModal}>
        "{deleteTarget?.question}" 을(를)삭제하시겠습니까?
      </Modal>
    </div>
  );
};

export default FaqListTab;
