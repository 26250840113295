import React from 'react';
import { Tabs } from 'antd';
import {
  DashboardOutlined,
  BookOutlined,
} from '@ant-design/icons';
import CollectionListTab from "./CollectionListTab";
import CollectionDashboardTab from "./CollectionDashboardTab";

const CollectionPage = () => {
  return (
    <div>
      <Tabs
        items={[
          {
            label: (
              <span>
                <DashboardOutlined />
                대시보드
              </span>
            ),
            key: 0,
            children: <CollectionDashboardTab />,
          },
          {
            label: (
              <span>
                <BookOutlined />
                미니북
              </span>
            ),
            key: 1,
            children: <CollectionListTab />,
          },
        ]}
      />
    </div>
  );
};

export default CollectionPage;
