import React from 'react';
import {Button, Col, Form, Input, Row} from 'antd';
import logo from '../../images/logo_intelz_black.png';
import {isProductionServer} from "../../utils/variables";
import axios from "axios";
import {API_URL_LOGIN, saveUserAndTokens} from "../../utils/authorization";
import {useDispatch} from "react-redux";
import {saveUserInfo} from "../../store/auth";

const LoginPage = (props) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    axios.post(API_URL_LOGIN, values)
      .then(res => {
        saveUserAndTokens(res.data);
      })
      .catch(err => console.log('failed.. ', err))
  };

  return (
    <div
      style={{
        maxWidth: 300,
        margin: 'auto',
        textAlign: 'center',
      }}
    >
      <img src={logo} alt="logo" style={{ width: '100%', padding: '40px 0' }} />

      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onFinish}
      >
        <Form.Item
          label="이메일"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="비밀번호"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Row gutter={8}>
          <Col span={12}>
            <Button block href={isProductionServer ? "https://youbook.biz" : "https://dev.youbook.biz"} >Go to YouBook</Button>
          </Col>
          <Col span={12}>
            <Button type="primary" block onClick={() => form.submit()} >Login</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default LoginPage;
