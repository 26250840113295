const { protocol } = window.location;

export let isProductionServer;
export let API_HOST;

switch (process.env.REACT_APP_DEPLOYMENT_ENV) {
  case 'production': // Production Server
    isProductionServer = true;
    API_HOST = `${protocol}//api.youbook.biz`;
    break;
  case 'development': // Staging Server
    isProductionServer = false;
    API_HOST = `${protocol}//dev-api.youbook.biz`;
    break;
  default: // Local
    isProductionServer = false;
    API_HOST = 'http://localhost:6001';
    break;
}
