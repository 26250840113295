import {Spin} from "antd";

const LoadingPage = () => {
    return (
        <Spin
            tip="Loading"
            size="large"
            style={{
                position: 'fixed',
                top: '50%',
                left: '50%'
            }}
        />
    );
}

export default LoadingPage
