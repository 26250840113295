import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveUserInfo: (state, action) => ({ ...state, isLoading: false, userInfo: action.payload }),
    clearUserInfo: (state, action) => ({ ...state, isLoading: false, userInfo: undefined }),
  },
});

export default slice.reducer;

// Actions

export const {
  saveUserInfo,
  clearUserInfo,
} = slice.actions;
