import React from 'react';
import { Tabs } from 'antd';
import {
  DashboardOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import PurchaseListTab from "./PurchaseListTab";
import PurchaseDashboardTab from "./PurchaseDashboardTab";
import EarnerListTab from "./EarnerListTab";
import SettlementListTab from "./SettlementListTab";

const PurchasePage = () => {
  return (
    <div>
      <Tabs
        items={[
          {
            label: (
              <span>
                <DashboardOutlined />
                대시보드
              </span>
            ),
            key: 0,
            children: <PurchaseDashboardTab />,
          },
          {
            label: (
              <span>
                <DollarOutlined />
                구매 목록
              </span>
            ),
            key: 1,
            children: <PurchaseListTab />,
          },
          {
            label: (
              <span>
                <DollarOutlined />
                수익자 목록
              </span>
            ),
            key: 2,
            children: <EarnerListTab />,
          },
          {
            label: (
              <span>
                <DollarOutlined />
                정산 내역
              </span>
            ),
            key: 3,
            children: <SettlementListTab />,
          },
        ]}
      />
    </div>
  );
};

export default PurchasePage;
