import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {getCellList, selectCell} from '../../../store/cells';
import moment from "moment";
import SearchableTable from "../../organisms/SearchableTable";
import CellDetailDrawer from "./CellDetailDrawer";

const CellListTab = () => {
  const dispatch = useDispatch();

  const { cellListObj } = useSelector((state) => state.cells);

  const _cellListLoading = cellListObj.isLoading
  const _totalCount = cellListObj.totalCount
  const _cellList = cellListObj.data

  useEffect(() =>  {
    dispatch(getCellList())
  }, [dispatch]);

  const columns = [
    {
      title: '제목',
      dataIndex: 'topic',
      isSearchable: true,
    },
    {
      title: '글감의 출처 제목',
      dataIndex: 'referenceTitle',
      isSearchable: true,
    },
    {
      title: '생성 시간',
      dataIndex: 'createdAt',
      render: (createdAt) => moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '제작자',
      dataIndex: 'editor',
      isSearchable: true,
    },
    {
      title: '조회수',
      dataIndex: 'viewCount',
      render: (viewCount) => `${viewCount}`,
      sorter: (a, b) => (a.viewCount - b.viewCount),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '포함된 컬렉션 수',
      dataIndex: 'collections',
      render: (collections) => `${collections.length}`,
    },
  ]

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <span style={{ fontSize: 30, margin: 5 }}>
          페이지
        </span>
        <span style={{ fontSize: 15, margin: 5 }}>
          전체 {_totalCount}개
        </span>
      </div>
      <SearchableTable
        dataSource={_cellList}
        columns={columns}
        loading={_cellListLoading}
        totalCount={_totalCount}
        onRowClick={(record) => dispatch(selectCell(record))}
        loadData={(query) => {
          dispatch(getCellList(query))
        }}
      />
      <CellDetailDrawer />
    </>
  );
};

export default CellListTab;
