import { createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import {mergeDict} from "../utils";

const initialState = {
  userCreatedAtListLoading: true,
  userCreatedAtList: [],
  userViewedAtListLoading: true,
  userViewedAtList: [],
  userListLoading: true,
  userList: [],
  userListObj: {
    isLoading: true,
    totalCount: 0,
    data: [],
  },
  deletedUserListObj: {
    isLoading: true,
    totalCount: 0,
    data: [],
  },
  phoneNumberObj: {
    isLoading: true,
    totalCount: 0,
    data: [],
  },
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserCreatedAtListLoading: (state, action) => ({
      ...state,
      userCreatedAtListLoading: action.payload
    }),
    setUserCreatedAtList: (state, action) => ({
      ...state,
      userCreatedAtListLoading: false,
      userCreatedAtList: action.payload,
    }),
    setUserViewedAtListLoading: (state, action) => ({
      ...state,
      userViewedAtListLoading: action.payload
    }),
    setUserViewedAtList: (state, action) => {
      return ({
        ...state,
        userViewedAtListLoading: false,
        userViewedAtList: action.payload,
      })
    },
    setUserListLoading: (state, action) => ({
      ...state,
      userListObj: {
        ...state.userListObj,
        isLoading: action.payload,
      },
    }),
    setUserList: (state, action) => ({
      ...state,
      userListObj: {
        ...state.userListObj,
        isLoading: false,
        totalCount: action.payload.totalCount,
        data: action.payload.users,
      },
    }),
    setDeletedUserListLoading: (state, action) => ({
      ...state,
      deletedUserListObj: {
          ...state.deletedUserListObj,
          isLoading: action.payload,
      },
    }),
    setDeletedUserList: (state, action) => ({
      ...state,
      deletedUserListObj: {
          ...state.deletedUserListObj,
          isLoading: false,
          totalCount: action.payload.totalCount,
          data: action.payload.users
      },
    }),
    setPhoneNumberListLoading: (state, action) => ({
      ...state,
      phoneNumberObj: {
        ...state.phoneNumberObj,
        isLoading: action.payload,
      },
    }),
    setPhoneNumberList: (state, action) => ({
      ...state,
      phoneNumberObj: {
        ...state.phoneNumberObj,
        isLoading: false,
        totalCount: action.payload.totalCount,
        data: action.payload.phoneNumbers,
      },
    }),
    updateUser: (state, action) => {
      const newOne = action.payload
      const oldOne = state.userListObj.data.find(user => user.id === newOne?.id)
      if (oldOne) {
        mergeDict(oldOne, newOne)
      }
    },
    deleteUserFromList: (state, action) => {
      state.userListObj.data = state.userListObj.data.filter(
        (user) => user.id !== action.payload
      );
    },
    selectUser: (state, action) => ({ ...state, selectedUser: action.payload }),
    selectPhoneNumber: (state, action) => ({ ...state, selectedPhoneNumber: action.payload }),
  },
});

export default slice.reducer;

// Actions

export const {
  setUserCreatedAtListLoading,
  setUserCreatedAtList,
  setUserViewedAtListLoading,
  setUserViewedAtList,
  setUserListLoading,
  setUserList,
  setDeletedUserListLoading,
  setDeletedUserList,
  setPhoneNumberListLoading,
  setPhoneNumberList,
  updateUser,
  deleteUserFromList,
  selectUser,
  selectPhoneNumber,
} = slice.actions;

export const getUserCreatedAtList = () => async (dispatch) => {
  dispatch(setUserCreatedAtListLoading(true));
  axios
    .get('/v1/admin/users/createdAt')
    .then(res => {
      dispatch(
        setUserCreatedAtList(
          res.data.map((time, key) => ({ time, key }))
        )
      );
    })
    .catch(e =>
      dispatch(setUserCreatedAtListLoading(false))
    )
};

export const getUserViewedAtList = () => async (dispatch) => {
  dispatch(setUserViewedAtListLoading(true));
  axios
    .get('/v1/admin/cells/viewedAt')
    .then(res => {
      dispatch(
        setUserViewedAtList(
          res.data.map(item => ({ time: item.viewedAt, key: item.userId }))
        )
      );
    })
    .catch(e =>
      dispatch(setUserViewedAtListLoading(false))
    )
};

export const getUserList = (query) => async (dispatch) => {
  dispatch(setUserListLoading(true));

  const params  = query ?? { skip: 0, limit: 10 }

  axios
    .get('/v1/admin/users', { params })
    .then((res) => {
      dispatch(setUserList(res.data))
    })
    .catch(e =>
      dispatch(setUserListLoading(false))
    )
};

export const getDeletedUserList = (query) => async (dispatch) => {
  dispatch(setDeletedUserListLoading(true));

  const params  = query ?? { skip: 0, limit: 10 }
  params.isDeleted = true

  axios
    .get('/v1/admin/users', { params })
    .then((res) => {
      dispatch(setDeletedUserList(res.data))
    })
    .catch(e =>
      dispatch(setDeletedUserListLoading(false))
    )
};

const formatPhoneNumber = (number) => {
  if (number.startsWith('82')) {
    number = number.replace('82', '0')
  }

  if (number.length === 11) {
    return `${number.slice(0, 3)}-${number.slice(3, 7)}-${number.slice(7)}`
  } else if (number.length === 10) {
    return `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6)}`
  } else {
    return number
  }
}

const isMobileNumber = (number) => {
  return number.startsWith('8210') && number.length === 12
}

export const getPhoneNumberList = (query) => async (dispatch) => {
  dispatch(setPhoneNumberListLoading(true));

  const params  = query ?? { skip: 0, limit: 10 }

  axios
    .get('/v1/admin/phone-numbers', { params })
    .then((res) => {
      dispatch(setPhoneNumberList({
        totalCount: res.data.totalCount,
        phoneNumbers: res.data.phoneNumbers.map(item => ({
          ...item,
          formattedNumber: formatPhoneNumber(item.number),
          isMobileNumber: isMobileNumber(item.number),
        }))
      }))
    })
    .catch(e =>
      dispatch(setPhoneNumberListLoading(false))
    )
};
