import React from 'react';
import {
  Button,
  Drawer,
  Form,
  Input,
  message,
  Space,
  InputNumber
} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {insertOrUpdateFaq, selectFaq} from "../../../store/faqs";
import axios from "axios";

const FaqDetailDrawer = () => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const { selectedFaq } = useSelector((state) => state.faqs);

  if (!!selectedFaq) {
    form.setFieldsValue(selectedFaq)
  } else {
    form.resetFields()
  }

  const isCreateMode = () => {
    return !(selectedFaq?._id)
  }

  const closeDrawer = () => {
    dispatch(selectFaq(null))
  }

  const key = 'update_server';
  const onFinish = (values) => {
    messageApi.open({
      key,
      type: 'loading',
      content: '서버에 업로드 중...',
    });

    (isCreateMode()
      ? axios.post(`/v1/admin/faqs`, values)
      : axios.put(`/v1/admin/faqs/${selectedFaq._id}`, values))
      .then(res => {
        closeDrawer()
        messageApi.open({
          key,
          type: 'success',
          content: isCreateMode() ? '생성되었습니다.' : '수정되었습니다.',
        });
        dispatch(insertOrUpdateFaq(res.data))
      })
      .catch(err => {
        messageApi.destroy(key)
      })
  };

  return (
      <>
        {contextHolder}
        <Drawer
            title={isCreateMode() ? "FAQ 생성" : "FAQ 수정"}
            width={600}
            onClose={closeDrawer}
            open={!!selectedFaq}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
              <Space>
                <Button onClick={closeDrawer}>취소</Button>
                <Button onClick={() => form.submit()} type="primary">
                  {isCreateMode() ? "생성" : "수정"}
                </Button>
              </Space>
            }
        >
          <Form
            layout="horizontal"
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 4 }}
          >
            <Form.Item
              name="index"
              label="순서"
              rules={[
                {
                  required: true,
                  message: '순서를 입력해 주세요.',
                },
              ]}
            >
              <InputNumber
                min={1}
                max={100}
                keyboard={true}
              />
            </Form.Item>
            <Form.Item
              name="question"
              label="제목"
              rules={[
                {
                  required: true,
                  message: '제목을 입력해 주세요.',
                },
              ]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>
            <Form.Item
              name="answer"
              label="내용"
              rules={[
                {
                  required: true,
                  message: '내용을 입력해 주세요.',
                },
              ]}
            >
              <Input.TextArea rows={10} />
            </Form.Item>
          </Form>
        </Drawer>
      </>
  );
};

export default FaqDetailDrawer;
