import { createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import {mergeDict} from "../utils";

const initialState = {
  cellCreatedAtListLoading: true,
  cellCreatedAtList: [],
  cellViewedAtListLoading: true,
  cellViewedAtList: [],
  cellListObj: {
    isLoading: true,
    totalCount: 0,
    data: [],
  }
};

const slice = createSlice({
  name: 'cells',
  initialState,
  reducers: {
    setCellCreatedAtListLoading: (state, action) => ({
      ...state,
      cellCreatedAtListLoading: action.payload
    }),
    setCellCreatedAtList: (state, action) => ({
      ...state,
      cellCreatedAtListLoading: false,
      cellCreatedAtList: action.payload,
    }),
    setCellViewedAtListLoading: (state, action) => ({
      ...state,
      cellViewedAtListLoading: action.payload
    }),
    setCellViewedAtList: (state, action) => ({
      ...state,
      cellViewedAtListLoading: false,
      cellViewedAtList: action.payload,
    }),
    setCellListLoading: (state, action) => ({
      ...state,
      cellListObj: {
        ...state.cellListObj,
        isLoading: action.payload,
      }
    }),
    setCellList: (state, action) => ({
      ...state,
      cellListObj: {
        ...state.cellListObj,
        isLoading: false,
        totalCount: action.payload.totalCount,
        data: action.payload.cells,
      },
    }),
    selectCell: (state, action) => ({ ...state, selectedCell: action.payload }),
    updateCell: (state, action) => {
      const newOne = action.payload.cell
      const oldOne = state.cellListObj.data.find(cell => cell.id === newOne?.id)
      if (oldOne) {
        mergeDict(oldOne, newOne)
      }
    },
  },
});

export default slice.reducer;

// Actions

export const {
  setCellCreatedAtListLoading,
  setCellCreatedAtList,
  setCellViewedAtListLoading,
  setCellViewedAtList,
  setCellListLoading,
  setCellList,
  selectCell,
  updateCell,
} = slice.actions;

export const getCellCreatedAtList = () => async (dispatch) => {
  dispatch(setCellCreatedAtListLoading(true));
  axios
    .get('/v1/admin/cells/createdAt')
    .then(res => {
      dispatch(
        setCellCreatedAtList(
          res.data.map((time, key) => ({ time, key }))
        )
      );
    })
    .catch(e =>
      dispatch(setCellCreatedAtListLoading(false))
    )
};

export const getCellViewedAtList = () => async (dispatch) => {
  dispatch(setCellViewedAtListLoading(true));
  axios
    .get('/v1/admin/cells/viewedAt')
    .then(res => {
      dispatch(
        setCellViewedAtList(
          res.data.map((item, index) => ({ time: item.viewedAt, key: index }))
        )
      );
    })
    .catch(e =>
      dispatch(setCellViewedAtListLoading(false))
    )
};

export const getCellList = (query) => async (dispatch) => {
  dispatch(setCellListLoading(true));

  const params  = query ?? { skip: 0, limit: 10 }

  axios
    .get('/v1/admin/cells', { params })
    .then(res => {
      dispatch(setCellList(res.data))
    })
    .catch(e =>
      dispatch(setCellListLoading(false))
    )
};
