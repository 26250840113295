import React from 'react';
import { Tabs } from 'antd';
import {
  DashboardOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';
import CellListTab from "./CellListTab";
import CellDashboardTab from "./CellDashboardTab";

const CellPage = () => {
  return (
    <div>
      <Tabs
        items={[
          {
            label: (
              <span>
                <DashboardOutlined />
                대시보드
              </span>
            ),
            key: 0,
            children: <CellDashboardTab />,
          },
          {
            label: (
              <span>
                <AppstoreOutlined />
                페이지
              </span>
            ),
            key: 1,
            children: <CellListTab />,
          },
        ]}
      />
    </div>
  );
};

export default CellPage;
