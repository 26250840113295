import { createSlice } from '@reduxjs/toolkit';
import {mergeDict} from "../utils";

const initialState = {
  faqListLoading: true,
  faqList: [],
};

const slice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    setFaqListLoading: (state, action) => ({
      ...state,
      faqListLoading: action.payload
    }),
    setFaqList: (state, action) => ({
      ...state,
      faqListLoading: false,
      faqList: action.payload,
    }),
    insertOrUpdateFaq: (state, action) => {
      const newOne = action.payload
      const oldOne = state.faqList.find(faq => faq._id === newOne._id)
      if (oldOne) {
        mergeDict(oldOne, newOne)
      } else {
        state.faqList.push(newOne);
      }
      state.faqList.sort((a, b) => a.index - b.index);
    },
    deleteFaqFromList: (state, action) => {
      state.faqList = state.faqList.filter(
        (faq) => faq._id !== action.payload
      );
    },
    selectFaq: (state, action) => ({ ...state, selectedFaq: action.payload }),
  },
});

export default slice.reducer;

// Actions

export const {
  setFaqListLoading,
  setFaqList,
  insertOrUpdateFaq,
  deleteFaqFromList,
  selectFaq,
} = slice.actions;
