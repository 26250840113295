import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchableTable from "../../organisms/SearchableTable";
import {getPhoneNumberList, selectPhoneNumber} from "../../../store/users";
import {Button, Input, message, Modal} from "antd";
import axios from "axios";
import PhoneNumberDetailDrawer from "./PhoneNumberDetailDrawer";

const SMS_TARGET_YOUBOOKER = 'youbooker';
const SMS_TARGET_NOT_YOUBOOKER = 'not-youbooker';

const PhoneNumberTab = () => {
  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const { phoneNumberObj } = useSelector((state) => state.users);

  const [smsTarget, setSmsTarget] = useState(null);
  const [smsText, setSmsText] = useState(null);
  const [isSendingSms, setIsSendingSms] = useState(false);

  useEffect(() => {
    dispatch(getPhoneNumberList())
  }, [dispatch]);

  const makeEmailList = (users) => {
    if (!users || users.length === 0) {
      return ''
    } else if (users.length === 1) {
      return users[0]
    } else {
      return `${users[0]} 외 ${users.length - 1}명`
    }
  }

  const sendSms = () => {
    if (!smsText) {
      messageApi.error('메시지를 입력하세요.');
      return;
    }

    setIsSendingSms(true)

    axios.post('/v1/admin/sms', {
      target: smsTarget,
      text: smsText,
    })
      .then((res) => {
        console.log(res)
        hideSmsModal()
        setIsSendingSms(false)
        messageApi.success('문자 메시지를 성공적으로 보냈습니다.');
      })
      .catch((e) => {
        console.log(e.response?.data?.message)
        setIsSendingSms(false)
        messageApi.error(`문자 메시지를 보내는데 실패했습니다. (${e.response?.data?.message})`);
      })
  }

  const columns = [
    {
      title: '전화번호',
      dataIndex: 'number',
      isSearchable: true,
    },
    {
      title: '국내 휴대전화 여부',
      dataIndex: 'isMobileNumber',
      render: (isMobileNumber) => isMobileNumber ? '국내 휴대전화' : '기타',
      filters: [
        { text: '국내 휴대전화', value: true },
        { text: '기타', value: false },
      ],
      filterMultiple: false,
    },
    {
      title: '소유한 사용자',
      dataIndex: 'owners',
      render: (owners) => makeEmailList(owners),
    },
    {
      title: '알고 있는 사용자',
      dataIndex: 'knowers',
      render: (knowers) => makeEmailList(knowers.map((knower) => knower.user)),
    },
    {
      title: 'SMS',
      key: 'action',
      render: (record) => (
        <Button
          type="link"
          onClick={(e) => {
            e.stopPropagation();
            showSmsModal({number: record.number});
          }}
        >
          문자전송
        </Button>
      ),
    },
  ];

  const showSmsModal = (target) => {
    setSmsText('')
    setSmsTarget(target)
  }

  const hideSmsModal = () => {
    setSmsTarget(null)
  }

  return (
    <>
      {contextHolder}
      <div style={{ marginBottom: 10 }}>
        <span style={{ fontSize: 30, margin: 5 }}>
          전화번호
        </span>
        <span style={{ fontSize: 15, margin: 5 }}>
          전체 {phoneNumberObj.totalCount}개
        </span>
        <Button style={{ margin: 5 }} onClick={() => showSmsModal({group: SMS_TARGET_YOUBOOKER})}>가입유저에게 문자전송</Button>
        <Button style={{ margin: 5 }} onClick={() => showSmsModal({group: SMS_TARGET_NOT_YOUBOOKER})}>비가입유저에게 문자전송</Button>
      </div>
      <SearchableTable
        dataSource={phoneNumberObj.data}
        columns={columns}
        loading={phoneNumberObj.isLoading}
        totalCount={phoneNumberObj.totalCount}
        onRowClick={(record) => dispatch(selectPhoneNumber(record))}
        loadData={(query) => { dispatch(getPhoneNumberList(query)) }}
      />
      <PhoneNumberDetailDrawer showSmsModal={showSmsModal} />
      <Modal
        title={`문자전송 (${(smsTarget?.group === SMS_TARGET_YOUBOOKER ? '가입유저 전체' : smsTarget?.group === SMS_TARGET_NOT_YOUBOOKER ? '비가입유저 전체' : smsTarget?.number)})`}
        open={!!smsTarget}
        onOk={() => sendSms()}
        confirmLoading={isSendingSms}
        onCancel={hideSmsModal}
        maskClosable={false}>
        <Input.TextArea rows={10} value={smsText} onChange={(e)=>{setSmsText(e.target.value)}}/>
      </Modal>
    </>
  );
};

export default PhoneNumberTab;
