import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCollectionCreatedAtList, getCollectionViewedAtList} from "../../../store/collections";
import TimeGraph from "../../organisms/TimeGraph";
import {Radio} from "antd";

const CollectionDashboardTab = () => {
  const dispatch = useDispatch();
  const {
    collectionCreatedAtListLoading,
    collectionCreatedAtList,
    collectionViewedAtListLoading,
    collectionViewedAtList,
  } = useSelector((state) => state.collections);
  const [category, setCategory] = useState('create')

  useEffect(() =>  {
    if (category === 'create' && collectionCreatedAtList.length === 0) {
      dispatch(getCollectionCreatedAtList())
    } else if (category === 'view' && collectionViewedAtList.length === 0) {
      dispatch(getCollectionViewedAtList())
    }
  }, [dispatch, category]);

  return (
    <>
      <TimeGraph
        categoryRadio={(
          <Radio.Group defaultValue='create' optionType="button" onChange={(e) => setCategory(e.target.value)}>
            <Radio.Button value='create'>생성</Radio.Button>
            {/*<Radio.Button value='view'>조회</Radio.Button>*/}
          </Radio.Group>
        )}
        loading={category === 'create' ? collectionCreatedAtListLoading: collectionViewedAtListLoading}
        timeKeyList={category === 'create' ? collectionCreatedAtList : collectionViewedAtList}
        yFieldName={category === 'create' ? '생성': '조회'}
      />
    </>
  );
};

export default CollectionDashboardTab;
