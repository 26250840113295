import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {getEarnerList} from '../../../store/purchases';
import SearchableTable from "../../organisms/SearchableTable";
import {amountTextByCurrency} from "../../../utils";

const EarnerListTab = () => {
  const dispatch = useDispatch();
  const { earnerList, earnerListLoading } = useSelector((state) => state.purchases);

  useEffect(() =>  {
    dispatch(getEarnerList())
  }, [dispatch]);

  const columns = [
    {
      title: '수익자 (이메일)',
      dataIndex: 'email',
      isSearchable: true,
    },
    {
      title: '수익자 이름',
      dataIndex: 'name',
      isSearchable: true,
    },
    {
      title: '총 수익금',
      dataIndex: 'totalSalesAmount',
      render: (totalSalesAmount) => amountTextByCurrency(totalSalesAmount),
    },
    {
      title: '정산 금액',
      dataIndex: 'totalSettledAmount',
      render: (totalSettledAmount) => amountTextByCurrency(totalSettledAmount),
    },
    {
      title: '정산 세금',
      dataIndex: 'totalSettledTax',
      render: (totalSettledTax) => amountTextByCurrency(totalSettledTax),
    },
    {
      title: '미정산 금액 (원화로 환산)',
      dataIndex: 'unsettledAmount',
      render: (unsettledAmount) => amountTextByCurrency(unsettledAmount),
    },
  ]

  return (
    <>
      <div style={{ marginBottom: 10 }}>
                <span style={{ fontSize: 30, margin: 5 }}>
                    수익자
                </span>
        <span style={{ fontSize: 15, margin: 5 }}>
                    전체 {earnerList.length}명
                </span>
      </div>
      <SearchableTable
        dataSource={earnerList}
        columns={columns}
        loading={earnerListLoading}
      />
    </>
  );
};

export default EarnerListTab;
