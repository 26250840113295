import React, {useEffect} from 'react';
import {
  Radio,
  Button,
  Drawer,
  Form,
  Image,
  Input,
  message,
  Space,
  Row,
  Col,
  Divider,
  Tabs,
  Select,
  Modal,
} from "antd";
import {languageCodes, majorLanguageCodes, publishStatus} from "../../../utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {selectCollection, updateCollection} from "../../../store/collections";
import axios from "axios";
import moment from "moment";
import {PlusOutlined} from "@ant-design/icons";
const { Option } = Select;

const CollectionDetailDrawer = () => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const { selectedCollection } = useSelector((state) => state.collections); // FIXME redux 안쓰고, 로컬로 해보기.

  const [pages, setPages] = React.useState(undefined)
  const [activeKey, setActiveKey] = React.useState('1')

  const [addLanguageTargetId, setAddLanguageTargetId] = React.useState(undefined);
  const [addLanguageSelected, setAddLanguageSelected] = React.useState(undefined);
  const [addLanguageLoading, setAddLanguageLoading] = React.useState(false);

  useEffect(() => {
    if (!!selectedCollection) {
      form.setFieldsValue(selectedCollection)

      axios
        .get(`/v1/admin/collections/${selectedCollection.id}`)
        .then(res => {
          setPages(res.data?.collection?.cells)
        })
        .catch(err => {
          messageApi.error('페이지를 불러오는데 실패했습니다.');
        })
    } else {
      form.resetFields()
      setActiveKey('1')
    }
  }, [selectedCollection]);

  const closeDrawer = () => {
    dispatch(selectCollection(null))
  }

  const key = 'update_server';
  const onFinish = (values) => {
    messageApi.open({
      key,
      type: 'loading',
      content: '서버에 업로드 중...',
    });

    axios.put(`/v1/admin/collections/${values.id}/${values.language}`, values)
      .then(res => {
        closeDrawer()
        messageApi.open({
          key,
          type: 'success',
          content: '수정되었습니다.',
        });
        dispatch(updateCollection(res.data))
      })
      .catch(err => {
        messageApi.destroy(key)
      })
  };

  const getPageContentRow = (title, content) => (
    <Row gutter={16}>
      <Col span={6}><p style={{ fontWeight: 'bold', textAlign: 'right' }}>{title}</p></Col>
      <Col span={18}><p>{content}</p></Col>
    </Row>
  )


  const getPageDetail = (page) => (
    <>
      <Row gutter={16}>
        <Col span={6}>
          <Image
            src={page.imageURL}
            style={{ width:"176px", height:"176px", objectFit:"contain" }}
          />
        </Col>
        <Col span={18}>
          {getPageContentRow('제목', page.topic)}
          {getPageContentRow('주제어', page.keywords)}
          {getPageContentRow('내용', page.knowledge?.split('\n')?.map((line, i) => <p key={i}>{line}</p>))}
          {getPageContentRow('글감의 출처 유형', page.referenceType)}
          {getPageContentRow('글감의 출처 제목', page.referenceTitle)}
          {getPageContentRow('출처에 대한 소개 링크', page.referenceURL)}
          {getPageContentRow('글감의 출판년도', page.referenceYear)}
          {getPageContentRow('글감의 저자', page.referenceAuthor)}
        </Col>
      </Row>
    </>
  )

  const onChangeLanguage = (collectionId, language) => {
    axios
      .get(`/v1/admin/collections/${collectionId}?language=${language}`)
      .then(res => {
        dispatch(selectCollection(res.data.collection))
      })
      .catch(err => {
        messageApi.error('데이터를 불러오는데 실패했습니다.');
      })
  }

  const showAddLanguageModal = (collectionId) => {
    setAddLanguageSelected(undefined)
    setAddLanguageTargetId(collectionId)
  }

  const addLanguage = () => {
    if (!addLanguageSelected) {
      messageApi.error('언어를 선택해주세요.');
      return
    }

    setAddLanguageLoading(true);

    axios
      .post(
        `/v1/admin/collections/${addLanguageTargetId}/languages`,
        { language: addLanguageSelected }
      )
      .then(res => {
        dispatch(selectCollection(res.data))
        setAddLanguageTargetId(undefined)
        setAddLanguageLoading(false)
      })
      .catch(err => {
        setAddLanguageLoading(false)
      })
  }

  return (
      <>
        {contextHolder}
        <Drawer
            title="미니북 상세"
            width={800}
            onClose={closeDrawer}
            open={!!selectedCollection}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
              <Space>
                <Button onClick={closeDrawer}>취소</Button>
                <Button onClick={() => form.submit()} type="primary">수정</Button>
              </Space>
            }
        >
          <Form
            layout="horizontal"
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 4 }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="id" label="UUID">
                  <Input disabled  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="생성 시간" >
                  <Input disabled value={moment(selectedCollection?.createdAt).format('YYYY/MM/DD HH:mm:ss')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="상태">
                  <Input disabled value={publishStatus[selectedCollection?.status] ?? selectedCollection?.status} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="제작자">
                  <Input disabled value={selectedCollection?.creator?.name} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="언어" name="language" labelCol={{ span: 6 }}>
                  <Select value={selectedCollection?.language} onChange={(value) => onChangeLanguage(selectedCollection?.id, value)}>
                    {
                      selectedCollection?.languages?.map(language => (
                        <Option value={language}>{languageCodes[language]}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button icon={<PlusOutlined />} onClick={() => showAddLanguageModal(selectedCollection?.id)} />
              </Col>
              <Col span={12}>
                <Form.Item label="필명" name="penName">
                  <Input value={selectedCollection?.penName} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="템플릿" name="templateId" labelCol={{ span: 6 }}>
                  <Input value={selectedCollection?.templateId} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="배경색" name="backgroundColor" labelCol={{ span: 6 }}>
                  <Input value={selectedCollection?.backgroundColor ?? undefined} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="글자색" name="fontColor" labelCol={{ span: 6 }}>
                  <Input value={selectedCollection?.fontColor ?? undefined} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item valuePropName="src" name="imageURL" label="이미지" labelCol={{ span: 6 }}>
                  <Image style={{ width:"158px", height:"158px", objectFit:"contain" }} />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item name="topic" label="제목">
                  <Input />
                </Form.Item>
                <Form.Item name="keywords" label="주제어">
                  <Input />
                </Form.Item>
                <Form.Item name="category" label="카테고리">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="knowledge" label="내용" labelCol={{ span: 2}}>
                  <Input.TextArea rows={4} placeholder="내용" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Divider> 페이지 </Divider>
          {
            !pages ? <p>페이지를 불러오는 중입니다.</p>
              : pages.length === 0 ? <p>페이지가 없습니다.</p>
                : (
                  <Tabs
                    activeKey={activeKey}
                    onChange={(key) => setActiveKey(key)}
                    type="card"
                    size={pages.length}
                    items={pages.map((page, i) => {
                      const id = String(i+1)
                      return {
                        label: `${id}. ${page.topic}`,
                        key: id,
                        children: getPageDetail(page)
                      };
                    })}
                  />
                )
          }
        </Drawer>
        <Modal
          title="언어 추가하기"
          open={!!addLanguageTargetId}
          onOk={addLanguage}
          onCancel={() => {setAddLanguageTargetId(undefined)}}
          confirmLoading={addLanguageLoading}
        >
          <Radio.Group onChange={(e) => setAddLanguageSelected(e.target.value)} value={addLanguageSelected}>
            <Space direction="vertical">
              {
                majorLanguageCodes
                  .map(code => (
                    <Radio value={code} disabled={selectedCollection?.languages?.includes(code)}>{languageCodes[code]}</Radio>
                  ))
              }
            </Space>
          </Radio.Group>
        </Modal>
      </>
  );
};

export default CollectionDetailDrawer;
