import {clearUserInfo, saveUserInfo} from "../store/auth";
import store from "../store/store";
import axios from "axios";
import {API_HOST} from "./variables";
import {message} from "antd";

const API_URL_REFRESH_TOKEN = '/v1/auth/refresh-tokens';
export const API_URL_LOGIN = '/v1/auth/login';

const KEY_USER_INFO = 'user_info'
const KEY_ACCESS_TOKEN = 'access_token';
const KEY_REFRESH_TOKEN = 'refresh_token';

export function getSavedUserInfo() {
  try {
    return JSON.parse(localStorage.getItem(KEY_USER_INFO))
  } catch (e) {
    return undefined
  }
}

// Access Token 을 가져옵니다.
export function getAccessToken() {
  return localStorage.getItem(KEY_ACCESS_TOKEN);
}

// Refresh Token 을 가져옵니다.
export function getRefreshToken() {
  return localStorage.getItem(KEY_REFRESH_TOKEN);
}

export const setAuthHeader = (accessToken) => {
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

export function saveUserAndTokens(res) {
  const { user, accessToken, refreshToken } = res
  localStorage.setItem(KEY_USER_INFO, JSON.stringify(user));
  localStorage.setItem(KEY_ACCESS_TOKEN, accessToken);
  localStorage.setItem(KEY_REFRESH_TOKEN, refreshToken);

  setAuthHeader(accessToken);

  store.dispatch(saveUserInfo(res.user));
}

export function logout() {
  localStorage.removeItem(KEY_USER_INFO)
  localStorage.removeItem(KEY_ACCESS_TOKEN)
  localStorage.removeItem(KEY_REFRESH_TOKEN)

  store.dispatch(clearUserInfo())
}

const isAuthApiUrl = (url) => {
  return url.split('/')[2] === 'auth';
};

export const axiosSettings = () => {
  axios.defaults.baseURL = API_HOST
  axios.defaults.timeout = 180000 // 3분

  axios.interceptors.response.use(
    (res) => res.data,
    async (error) => {
      let finalError = error;

      // 401 에러가 발생했을 때, Refresh Token 을 사용하여 새로운 Access Token 을 발급하고, 요청을 재실행합니다.
      const originalRequest = error.config;
      const isAuthApi = isAuthApiUrl(originalRequest.url);
      if (!isAuthApi && error.response?.status === 401 && !originalRequest.isRetryCall) {
        const refreshToken = getRefreshToken();
        if (refreshToken) {
          originalRequest.isRetryCall = true;
          try {
            const response = await axios.post(API_URL_REFRESH_TOKEN, { refreshToken });
            saveUserAndTokens(response.data);
            originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
            return axios(originalRequest);
          } catch (error2) {
            finalError = error2;
            // refresh token 에서도 에러가 발생했다면 로그아웃 처리.
            logout()
          }
        }
      }

      const errorCode = finalError.response?.data?.errorCode ?? finalError.response?.status
      const errorMessage = finalError.response?.data?.message ?? finalError.response?.statusText
      message.error(`[${errorCode}] ${errorMessage}`)

      if (!isAuthApi && finalError.response.status === 401) {
        logout()
      }

      return Promise.reject(error);
    }
  );
}
