import React, { useEffect, useState } from 'react';
import { Area } from '@ant-design/charts';
import moment from "moment";
import {DatePicker, Radio, Space} from "antd";
const { RangePicker } = DatePicker;

const GRAPH_GROUP_YEAR = 'year'
const GRAPH_GROUP_MONTH = 'month'
const GRAPH_GROUP_WEEK = 'week'
const GRAPH_GROUP_DAY = 'day'

const DEFAULT_GRAPH_GROUP = GRAPH_GROUP_MONTH

/**
 * 시계열 그래프
 * @param categoryRadio 카테고리 라디오 버튼
 * @param loading 그래프가 로딩중인지 여부
 * @param timeKeyList 시계열 데이터, List<{ time: 시간, key: 키 값(중복 체크), amount(기본값:1) }>
 * @param yFieldName y축에 표시할 이름
 */
const TimeGraph = ({categoryRadio, loading, timeKeyList, yFieldName}) => {
  const [grouping, setGrouping] = useState(DEFAULT_GRAPH_GROUP);
  const [selectedTime, setSelectedTime] = useState([]);
  const [data, setData] = useState([]);

  const getFormatByGroup = (grouping) => {
    return (grouping === GRAPH_GROUP_YEAR) ? 'YYYY'
      : (grouping === GRAPH_GROUP_MONTH) ? 'YYYY/MM'
        : (grouping === GRAPH_GROUP_WEEK) ? 'YYYY WW주'
          : 'YYYY/MM/DD'
  }

  const getXFieldByGroup = (createdAt, grouping) => {
    return createdAt && moment(createdAt).format(getFormatByGroup(grouping))
  }

  const isInSelectedRange = (time) => {
    if (!time) return false
    const [start, end] = selectedTime?.map(time => getXFieldByGroup(time, grouping))
    return (!start || time >= start)
      && (!end || time <= end)
  }

  useEffect(() => {
    const [selectedStartTime, selectedEndTime] = selectedTime

    const minTime = selectedStartTime ?? timeKeyList.map(item => item.time).filter(time => !!time).sort()[0]
    const maxTime = selectedEndTime ?? moment().toISOString()

    const minXField = getXFieldByGroup(minTime, grouping)
    const maxXField = getXFieldByGroup(maxTime, grouping)

    let nextTime = minTime
    let nextXField = minXField
    const xFields = [minXField]
    while (nextXField < maxXField) {
      nextTime = moment(nextTime).add(1, grouping).toISOString()
      nextXField = getXFieldByGroup(nextTime, grouping)
      xFields.push(nextXField)
    }

    const filteredList = timeKeyList
      .map(item => ({
        ...item,
        timeGroup: getXFieldByGroup(item.time, grouping),
      }))
      .filter(item => isInSelectedRange(item.timeGroup))

    setData(xFields.map(xField => {
      const dataByGroup = filteredList
        .filter(item => xField === item.timeGroup)

      const amount = dataByGroup.reduce((result, item) => {
        console.log('reduce. item = ', item.amount)
        if (!result.keys.includes(item.key)) {
          result.keys.push(item.key)
          result.amount = result.amount + (item.amount ?? 1)
        }
        return result
      }, {keys: [], amount: 0})
        .amount

      const result = { xField }
      result[yFieldName] = amount
      return result
    }));
  }, [timeKeyList, selectedTime, grouping])

  return (
    <>
      <Space direction='horizontal'>
        {categoryRadio}
        <Radio.Group defaultValue={DEFAULT_GRAPH_GROUP} onChange={(e) => setGrouping(e.target.value)} optionType="button">
          <Radio.Button value={GRAPH_GROUP_YEAR}>년</Radio.Button>
          <Radio.Button value={GRAPH_GROUP_MONTH}>월</Radio.Button>
          <Radio.Button value={GRAPH_GROUP_WEEK}>주</Radio.Button>
          <Radio.Button value={GRAPH_GROUP_DAY}>일</Radio.Button>
        </Radio.Group>
        <RangePicker
          picker={grouping}
          allowEmpty={[true, true]}
          onChange={(selected) => setSelectedTime(selected?.map(time => time?.toISOString()) ?? [])}
        />
      </Space>
      <Area
        loading={loading}
        data={data}
        xField='xField'
        yField={yFieldName}
        style={{ marginTop: '20px' }}
      />
    </>
  );
};

export default TimeGraph;

TimeGraph.defaultProps = {
  grouping: DEFAULT_GRAPH_GROUP
}
