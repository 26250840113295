import React from 'react';
import { Tabs } from 'antd';
import {
  DashboardOutlined,
  SketchOutlined,
} from '@ant-design/icons';
import SubscriptionListTab from "./SubscriptionListTab";
import SubscriptionDashboardTab from "./SubscriptionDashboardTab";

const SubscriptionPage = () => {
  return (
    <div>
      <Tabs
        items={[
          {
            label: (
              <span>
                <DashboardOutlined />
                대시보드
              </span>
            ),
            key: 0,
            children: <SubscriptionDashboardTab />,
          },
          {
            label: (
              <span>
                <SketchOutlined />
                구독 목록
              </span>
            ),
            key: 1,
            children: <SubscriptionListTab />,
          },
        ]}
      />
    </div>
  );
};

export default SubscriptionPage;
