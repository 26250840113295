import React, { useEffect, useState } from 'react';
import { Layout, Menu, Button, Space, Dropdown } from 'antd';
import {
  AppstoreOutlined,
  BookOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import logo from '../../images/logo_intelz.svg';
import UserPage from '../pages/UserPage/UserPage';
import FaqListTab from '../pages/FaqTab/FaqListTab';
import NotFoundPage from '../pages/NotFoundPage';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  MENU_USERS,
  MENU_CELLS,
  MENU_COLLECTIONS,
  MENU_FAQS,
  DEFAULT_MENU,
  MENU_SUBSCRIPTIONS,
  MENU_PURCHASES,
} from '../../utils/constants';
import CellPage from '../pages/CellPage/CellPage';
import CollectionPage from '../pages/CollectionPage/CollectionPage';
import { logout } from '../../utils/authorization';
import { useDispatch, useSelector } from 'react-redux';
import SubscriptionPage from '../pages/SubscriptionPage/SubscriptionPage';
import PurchasePage from '../pages/PurchasePage/PurchasePage';

const { Header, Content, Sider } = Layout;

const MainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { userInfo } = useSelector((state) => state.auth);

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(`/${DEFAULT_MENU}`, { replace: true });
    }
  }, [location, navigate]); // 의존성에 navigate 추가

  const getSelectedKey = () => {
    return [location.pathname.split('/')[1]];
  };

  const menu = (
    <Menu>
      <Menu.Item key="user-info">{userInfo.email}</Menu.Item> {/* key 추가 */}
      <Menu.Item key="logout"> {/* key 추가 */}
        <Button onClick={() => logout()}>Log Out</Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          background: '#041527',
          padding: 0,
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{ width: '180px', height: '100%', padding: '10px' }}
        />
        <Space style={{ float: 'right', marginRight: 30 }}>
          <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
            <UserOutlined style={{ fontSize: 20, color: '#fff' }} />
          </Dropdown>
        </Space>
      </Header>
      <Layout>
        <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
          <Menu
            theme="dark"
            defaultSelectedKeys={[DEFAULT_MENU]}
            selectedKeys={getSelectedKey()}
            mode="inline"
            onSelect={(event) => {
              navigate(`/${event.key}`);
            }}
          >
            <Menu.Item key={MENU_USERS}>
              <UserOutlined />
              <span>사용자</span>
            </Menu.Item>
            <Menu.Item key={MENU_CELLS}>
              <AppstoreOutlined />
              <span>페이지</span>
            </Menu.Item>
            <Menu.Item key={MENU_COLLECTIONS}>
              <BookOutlined />
              <span>미니북</span>
            </Menu.Item>
            {/*<Menu.Item key={MENU_SUBSCRIPTIONS}>*/}
            {/*    <SketchOutlined />*/}
            {/*    <span>구독</span>*/}
            {/*</Menu.Item>*/}
            <Menu.Item key={MENU_PURCHASES}>
              <DollarOutlined />
              <span>결제</span>
            </Menu.Item>
            <Menu.Item key={MENU_FAQS}>
              <QuestionCircleOutlined />
              <span>FAQ</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content style={{ margin: 30 }}>
          <div style={{ margin: 'auto', maxWidth: 1200 }}>
            <Routes>
              <Route path={`/${MENU_USERS}`} element={<UserPage />} />
              <Route path={`/${MENU_CELLS}`} element={<CellPage />} />
              <Route path={`/${MENU_COLLECTIONS}`} element={<CollectionPage />} />
              <Route path={`/${MENU_SUBSCRIPTIONS}`} element={<SubscriptionPage />} />
              <Route path={`/${MENU_PURCHASES}`} element={<PurchasePage />} />
              <Route path={`/${MENU_FAQS}`} element={<FaqListTab />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainPage;