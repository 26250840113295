import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {getPurchaseList} from '../../../store/purchases';
import moment from "moment";
import SearchableTable from "../../organisms/SearchableTable";
import {priceTextWithUsd} from "../../../utils";

const PurchaseListTab = () => {
  const dispatch = useDispatch();
  const { purchaseList, purchaseListLoading } = useSelector((state) => state.purchases);

  useEffect(() =>  {
    dispatch(getPurchaseList())
  }, [dispatch]);

  const columns = [
    {
      title: '구매자',
      dataIndex: ['purchaser', 'email'],
      isSearchable: true,
    },
    {
      title: '결제 시간',
      dataIndex: 'createdAt',
      render: (createdAt) => moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '결제 대상',
      dataIndex: ['target', 'topic'],
    },
    {
      title: '결제 언어',
      dataIndex: 'language',
    },
    {
      title: '정상가',
      dataIndex: ['target', 'price'],
      render: (price, record) => priceTextWithUsd(price ?? 0, record.target.currency),
    },
    {
      title: '결제 금액',
      dataIndex: 'amount',
      render: (amount, record) => priceTextWithUsd(amount ?? 0, record.currency),
    },
  ]

  return (
    <>
      <div style={{ marginBottom: 10 }}>
                <span style={{ fontSize: 30, margin: 5 }}>
                    결제 건
                </span>
        <span style={{ fontSize: 15, margin: 5 }}>
                    전체 {purchaseList.length}개
                </span>
      </div>
      <SearchableTable
        dataSource={purchaseList}
        columns={columns}
        loading={purchaseListLoading}
      />
    </>
  );
};

export default PurchaseListTab;
