import React from "react";
import {exchangeRateByCurrency} from "./constants";

export const mergeDict = (prev, cur) => {
  for (const key in cur) {
    prev[key] = cur[key];
  }
};

/**
 * Html String 에서 Text 만 추출.
 * TTS 를 잘 하기위해, 단락마다 '. ' 을 추가함.
 */
export function extractTextFromHTML(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const { body } = doc;

  function addDotToTextNode(node) {
    if (node.nodeType === 3) { // 3은 텍스트 노드를 의미
      const text = node.textContent.trim();
      if (text && !text.endsWith('.')) {
        // eslint-disable-next-line no-param-reassign
        node.textContent += '. ';
      }
    }
    Array.from(node.childNodes).forEach(addDotToTextNode);
  }

  addDotToTextNode(body);
  return body.textContent.trim();
}

export const amountText = (amount, currency) => {
  if (['KRW', 'IDR', 'JPY', 'VND'].includes(currency)) {
    amount = Math.round(amount);
  } else {
    amount = Math.round(amount * 100) / 100;
  }

  return `${currency} ${amount.toLocaleString()}`;
}

export const priceTextWithUsd = (amount, currency) => {
  const amountInUSD = amount / (exchangeRateByCurrency[currency] || 1);

  return (
    <>
      {amountText(amount, currency)}
      <br/>
      ({amountText(amountInUSD, 'USD')})
    </>
  );
};

export const amountTextByCurrency = (amounts) => {
  const amountTexts = Object.keys(amounts).map((currency) => {
    const amount = amounts[currency];
    return amountText(amount, currency);
  });

  return (
    <>
      {amountTexts.map((text, index) => (
        <span key={index}>
          {text}
          <br />
        </span>
      ))}
    </>
  );
};
