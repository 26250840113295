import React from 'react';
import {
  Button,
  Drawer,
  Form,
  Input,
} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectPhoneNumber} from "../../../store/users";

const PhoneNumberDetailDrawer = ({ showSmsModal }) => {

  const dispatch = useDispatch();

  const { selectedPhoneNumber } = useSelector((state) => state.users);

  const closeDrawer = () => {
    dispatch(selectPhoneNumber(null))
  }

  return (
    <Drawer
      title="전화번호 상세 정보"
      width={600}
      onClose={closeDrawer}
      open={!!selectedPhoneNumber}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        layout="horizontal"
        labelCol={{ span: 4 }}
      >
        <Form.Item label="전화번호">
          <Input value={selectedPhoneNumber?.formattedNumber} />
        </Form.Item>
        <Form.Item label="국내 휴대전화 여부">
          <Input value={selectedPhoneNumber?.isMobileNumber ? '국내 휴대전화' : '기타'} />
        </Form.Item>
        <Form.Item label={`소유 (${selectedPhoneNumber?.owners?.length}명)`}>
          <Input.TextArea autoSize value={
            selectedPhoneNumber?.owners?.length > 0
              ? selectedPhoneNumber?.owners?.join('\n')
              : ''
          } />
        </Form.Item>
        <Form.Item label={`업로드 (${selectedPhoneNumber?.knowers?.length}명)`}>
          <Input.TextArea autoSize value={
            selectedPhoneNumber?.knowers?.length > 0
              ? selectedPhoneNumber?.knowers?.map((knower) => `${knower.user} (${knower.knownAs})`)?.join('\n')
              : ''
          } />
        </Form.Item>
        <Form.Item label='SMS 전송'>
          <Button
            type='primary'
            onClick={() => {
              closeDrawer();
              showSmsModal({number: selectedPhoneNumber.number});
            }}
          >
            전송
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default PhoneNumberDetailDrawer;
