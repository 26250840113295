import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import faqs from './faqs';
import users from './users';
import cells from './cells';
import collections from './collections';
import subscriptions from './subscriptions';
import purchases from './purchases';
import auth from './auth';

const rootReducer = combineReducers({
  faqs,
  users,
  cells,
  collections,
  subscriptions,
  purchases,
  auth,
});

export const middlewares = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
