import { createSlice } from '@reduxjs/toolkit';
import axios from "axios";

const initialState = {
  subscriptionListLoading: true,
  subscriptionList: [],
};

const slice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSubscriptionListLoading: (state, action) => ({
      ...state,
      subscriptionListLoading: action.payload
    }),
    setSubscriptionList: (state, action) => ({
      ...state,
      subscriptionListLoading: false,
      subscriptionList: action.payload,
    }),
  },
});

export default slice.reducer;

// Actions
export const {
  setSubscriptionListLoading,
  setSubscriptionList,
} = slice.actions;

export const getSubscriptionList = () => async (dispatch) => {
  dispatch(setSubscriptionListLoading(true));
  axios
    .get('/v1/admin/subscriptions')
    .then(res => {
      dispatch(setSubscriptionList(res.data))
    })
    .catch(e =>
      dispatch(setSubscriptionListLoading(false))
    )
};
