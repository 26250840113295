import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getUserCreatedAtList, getUserViewedAtList} from "../../../store/users";
import TimeGraph from "../../organisms/TimeGraph";
import {Radio} from "antd";

const CATEGORY_CREATE = 'create'
const CATEGORY_VIEW = 'view'

const UserDashboardTab = () => {
  const dispatch = useDispatch();
  const {
    userCreatedAtListLoading,
    userCreatedAtList,
    userViewedAtListLoading,
    userViewedAtList,
  } = useSelector((state) => state.users);
  const [category, setCategory] = useState('create')

  useEffect(() =>  {
    if (category === CATEGORY_CREATE) {
      if (userCreatedAtList.length === 0) {
        dispatch(getUserCreatedAtList())
      }
    } else { // CATEGORY_VIEW
      if (userViewedAtList.length === 0) {
        dispatch(getUserViewedAtList())
      }
    }
  }, [dispatch, category]);

  return (
    <TimeGraph
      categoryRadio={(
        <Radio.Group defaultValue='create' optionType="button" onChange={(e) => setCategory(e.target.value)}>
          <Radio.Button value={CATEGORY_CREATE}>가입</Radio.Button>
          <Radio.Button value={CATEGORY_VIEW}>셀 조회 사용자</Radio.Button>
        </Radio.Group>
      )}
      loading={category === CATEGORY_CREATE ? userCreatedAtListLoading: userViewedAtListLoading}
      timeKeyList={category === CATEGORY_CREATE ? userCreatedAtList : userViewedAtList}
      yFieldName={category === CATEGORY_CREATE ? '가입자 수' : '사용자 수'}
    />
  );
};

export default UserDashboardTab;
