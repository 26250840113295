import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {getSubscriptionList} from '../../../store/subscriptions';
import moment from "moment";
import SearchableTable from "../../organisms/SearchableTable";

const SubscriptionListTab = () => {
  const dispatch = useDispatch();
  const { subscriptionList, subscriptionListLoading } = useSelector((state) => state.subscriptions);

  useEffect(() =>  {
    dispatch(getSubscriptionList())
  }, [dispatch]);

  const columns = [
    {
      title: '구독자',
      dataIndex: ['user', 'email'],
      isSearchable: true,
    },
    {
      title: '결제 시간',
      dataIndex: 'createdAt',
      render: (createdAt) => moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '구독 대상',
      dataIndex: 'target',
      render: (target) => target.organization ? `기관(${target.organization.name})` : '본인',
      filters: [
        { text: '기관', value: true },
        { text: '본인', value: false },
      ],
      filterMultiple: false,
      onFilter: (value, record) => !!record.target.organization === value,
    },
    {
      title: '구독 인원',
      dataIndex: 'subsPeople',
      render: (subsPeople, record) => record.amount ? subsPeople : '',
    },
    {
      title: '결제 금액',
      dataIndex: 'amount',
      render: (amount) => amount?.toLocaleString(),
      sorter: (a, b) => (a.amount ?? 0) - (b.amount ?? 0),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '구독 시작',
      dataIndex: 'subsStartAt',
      render: (subsStartAt) => subsStartAt ? moment(subsStartAt).format('YYYY-MM-DD') : '',
    },
    {
      title: '구독 종료',
      dataIndex: 'subsExpiredAt',
      render: (subsExpiredAt) => subsExpiredAt ? moment(subsExpiredAt).format('YYYY-MM-DD') : '',
    },
  ]

  return (
    <>
      <div style={{ marginBottom: 10 }}>
                <span style={{ fontSize: 30, margin: 5 }}>
                    구독 건
                </span>
        <span style={{ fontSize: 15, margin: 5 }}>
                    전체 {subscriptionList.length}개
                </span>
      </div>
      <SearchableTable
        dataSource={subscriptionList}
        columns={columns}
        loading={subscriptionListLoading}
      />
    </>
  );
};

export default SubscriptionListTab;
