import React from 'react';
import {
  Radio,
  Button,
  Drawer,
  Form,
  Image,
  Input,
  message,
  Space,
  Row,
  Col,
  Select,
  Modal,
} from "antd";
import {languageCodes, majorLanguageCodes, referenceTypes} from "../../../utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {selectCell, updateCell} from "../../../store/cells";
import axios from "axios";
import moment from "moment";
import {PlusOutlined} from "@ant-design/icons";
import {extractTextFromHTML} from "../../../utils";
const { Option } = Select;

const CellDetailDrawer = () => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const { selectedCell } = useSelector((state) => state.cells);

  const [addLanguageTargetId, setAddLanguageTargetId] = React.useState(undefined);
  const [addLanguageSelected, setAddLanguageSelected] = React.useState(undefined);
  const [addLanguageLoading, setAddLanguageLoading] = React.useState(false);

  if (!!selectedCell) {
    form.setFieldsValue(selectedCell)
  } else {
    form.resetFields()
  }

  const closeDrawer = () => {
    dispatch(selectCell(null))
  }

  const key = 'update_server';
  const onFinish = (values) => {
    messageApi.open({
      key,
      type: 'loading',
      content: '서버에 업로드 중...',
    });

    axios
      .put(
      `/v1/admin/cells/${values.id}/${values.language}`,
        {
          ...values,
          knowledgeText: extractTextFromHTML(values.knowledge),
          graphicDesigner: selectedCell?.graphicDesigner ?? '',
          editor: selectedCell?.editor ?? '',
        }
      )
      .then(res => {
        closeDrawer()
        messageApi.open({
          key,
          type: 'success',
          content: '수정되었습니다.',
        });
        dispatch(updateCell(res.data))
      })
      .catch(err => {
        messageApi.destroy(key)
      })
  };

  const onChangeLanguage = (cellId, language) => {
    axios
      .get(`/v1/public/cells/${cellId}?language=${language}`)
      .then(res => {
        dispatch(selectCell(res.data.cell))
      })
      .catch(err => {
        messageApi.error('데이터를 불러오는데 실패했습니다.');
      })
  }

  const showAddLanguageModal = (cellId) => {
    setAddLanguageSelected(undefined)
    setAddLanguageTargetId(cellId)
  }

  const addLanguage = () => {
    if (!addLanguageSelected) {
      messageApi.error('언어를 선택해주세요.');
      return
    }

    setAddLanguageLoading(true);

    axios
      .post(
        `/v1/admin/cells/${addLanguageTargetId}/languages`,
        { language: addLanguageSelected }
      )
      .then(res => {
        dispatch(selectCell(res.data))
        setAddLanguageTargetId(undefined)
        setAddLanguageLoading(false)
      })
      .catch(err => {
        setAddLanguageLoading(false)
      })
  }

  return (
      <>
        {contextHolder}
        <Drawer
            title="페이지 수정"
            width={600}
            onClose={closeDrawer}
            open={!!selectedCell}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
              <Space>
                <Button onClick={closeDrawer}>취소</Button>
                <Button onClick={() => form.submit()} type="primary">수정</Button>
              </Space>
            }
        >
          <Form
            layout="horizontal"
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 6 }}
          >
            <Form.Item name="id" label="UUID">
              <Input disabled bordered={false}  />
            </Form.Item>
            <Form.Item label="생성 시간">
              <Input disabled value={moment(selectedCell?.createdAt).format('YYYY/MM/DD HH:mm:ss')} bordered={false} />
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item label="언어" name="language" labelCol={{ span: 12 }}>
                  <Select value={selectedCell?.language} onChange={(value) => onChangeLanguage(selectedCell?.id, value)}>
                    {
                      selectedCell?.languages?.map(language => (
                        <Option value={language}>{languageCodes[language]}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={1} />
              <Col span={4}>
                <Button icon={<PlusOutlined />} onClick={() => showAddLanguageModal(selectedCell?.id)} />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item label="템플릿" name="templateId" labelCol={{ span: 18 }}>
                  <Input value={selectedCell?.templateId} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="배경색" name="backgroundColor" labelCol={{ span: 10 }}>
                  <Input value={selectedCell?.backgroundColor ?? undefined} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="글자색" name="fontColor" labelCol={{ span: 10 }}>
                  <Input value={selectedCell?.fontColor ?? undefined} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item valuePropName="src" name="imageURL" label="카드뉴스 이미지">
              <Image style={{ width:"118px", height:"118px", objectFit:"contain" }} />
            </Form.Item>
            <Form.Item name="topic" label="제목">
              <Input />
            </Form.Item>
            <Form.Item name="keywords" label="주제어">
              <Input />
            </Form.Item>
            <Form.Item name="knowledge" label="내용">
              <Input.TextArea rows={4} placeholder="내용" />
            </Form.Item>
            <Form.Item name="referenceType" label="글감의 출처 유형">
              <Radio.Group>
                {
                  referenceTypes.map(type =>
                    <Radio value={type}>{type}</Radio>
                  )
                }
              </Radio.Group>
            </Form.Item>
            <Form.Item name="referenceTitle" label="글감의 출처 제목">
              <Input />
            </Form.Item>
            <Form.Item name="referenceURL" label="출처에 대한 소개 링크">
              <Input />
            </Form.Item>
            <Form.Item name="referenceYear" label="글감의 출판년도">
              <Input />
            </Form.Item>
            <Form.Item name="referenceAuthor" label="글감의 저자">
              <Input />
            </Form.Item>
          </Form>
        </Drawer>
        <Modal
          title="언어 추가하기"
          open={!!addLanguageTargetId}
          onOk={addLanguage}
          onCancel={() => {setAddLanguageTargetId(undefined)}}
          confirmLoading={addLanguageLoading}
        >
          <Radio.Group onChange={(e) => setAddLanguageSelected(e.target.value)} value={addLanguageSelected}>
            <Space direction="vertical">
              {
                majorLanguageCodes
                  .map(code => (
                    <Radio value={code} disabled={selectedCell?.languages?.includes(code)}>{languageCodes[code]}</Radio>
                  ))
              }
            </Space>
          </Radio.Group>
        </Modal>
      </>
  );
};

export default CellDetailDrawer;
