import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Button} from 'antd';
import {getUserList, selectUser} from '../../../store/users';
import moment from "moment";
import {allUserClasses, USER_CLASS_NOTHING} from "../../../utils/constants";
import SearchableTable from "../../organisms/SearchableTable";
import {getDeletedUserList} from "../../../store/users";
import axios from "axios";
import UserDetailDrawer from "./UserDetailDrawer";

const UserListTab = ({ deletedUser }) => {
  const dispatch = useDispatch();

  const { userListObj, deletedUserListObj } = useSelector((state) => state.users);
  const obj = deletedUser ? deletedUserListObj : userListObj

  const _userListLoading = obj.isLoading
  const _totalCount = obj.totalCount
  const _userList = obj.data

  useEffect(() => {
    dispatch(deletedUser ? getDeletedUserList() : getUserList())
  }, [dispatch]);

  const downloadUserTable = async () => {
    axios
      .get(`/v1/admin/users/download`, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'users.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }

  const columns = [
    {
      title: '이름',
      dataIndex: deletedUser ? 'nameDeleted' : 'name',
      isSearchable: true,
    },
    {
      title: '이메일',
      dataIndex: deletedUser ? 'emailDeleted' : 'email',
      isSearchable: true,
    },
    {
      title: '휴대폰번호',
      dataIndex: deletedUser ? 'mobileDeleted' : 'mobile',
      isSearchable: true,
    },
    {
      title: '가입일',
      dataIndex: 'createdAt',
      render: (createdAt) => moment(createdAt).format('YYYY-MM-DD'),
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortDirections: ['descend', 'ascend'],
    },
    (
      deletedUser
        ? {
          title: '탈퇴일',
          dataIndex: 'deletedAt',
          render: (createdAt) => moment(createdAt).format('YYYY-MM-DD'),
          sorter: (a, b) => a.deletedAt.localeCompare(b.deletedAt),
          sortDirections: ['descend', 'ascend'],
        }
        : {
          title: '마지막 로그인',
          dataIndex: 'lastLoginTime',
          render: (lastLoginTime) => lastLoginTime ? moment(lastLoginTime).format('YYYY-MM-DD') : '',
          sorter: (a, b) => (a.lastLoginTime ?? '').localeCompare(b.lastLoginTime ?? ''),
          sortDirections: ['descend', 'ascend'],
        }
    ),
    {
      title: '언어',
      dataIndex: 'language',
      isSearchable: true,
    },
    {
      title: '국가',
      dataIndex: 'country',
      isSearchable: true,
    },
    {
      title: '클래스',
      dataIndex: 'userClasses',
      render: (userClasses) => userClasses?.join(', '),
      filters: allUserClasses.map(userClass => ({ value: userClass, text: userClass })),
      filterMultiple: false,
    },
    {
      title: '컬렉션 수',
      dataIndex: 'collectionCount',
      render: (collectionCount) => `${collectionCount}`,
      sorter: (a, b) => {
        const collectionCountA = a.collectionCount || 0
        const collectionCountB = b.collectionCount || 0
        return collectionCountA - collectionCountB
      },
      sortDirections: ['descend', 'ascend'],
    },
  ];

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <span style={{ fontSize: 30, margin: 5 }}>
          { deletedUser ? '탈퇴한 사용자' : '사용자'}
        </span>
        <span style={{ fontSize: 15, margin: 5 }}>
          전체 {_totalCount}개
        </span>
        {
          !deletedUser && (
            <Button onClick={downloadUserTable}>
              CSV 다운로드
            </Button>
          )
        }
      </div>
      <SearchableTable
        dataSource={_userList}
        columns={columns}
        loading={_userListLoading}
        totalCount={_totalCount}
        onRowClick={
          !deletedUser && ((record) =>  dispatch(selectUser(record)))
        }
        loadData={(query) => {
          dispatch(deletedUser ? getDeletedUserList(query) : getUserList(query))
        }}
      />
      <UserDetailDrawer />
    </>
  );
};

export default UserListTab;
